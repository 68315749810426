import React, {Component} from 'react'
import {connect} from 'react-redux'
// import classNames from 'classnames'
import TextField from 'material-ui/TextField'
import Dropzone from 'react-dropzone'
import RaisedButton from 'material-ui/RaisedButton';
// import {PropTypes} from 'prop-types'
import SvgIcon from 'material-ui/SvgIcon';

require('./UploadOption.scss')


class UploadOption extends Component {

  constructor(props) {
    super(props)
    this.state = {
      // files: []
    }

 }

  getFileErrorText(){
    return (this.props.files.length === 0 && this.props.tabError) ? "This field is required" : ""
  }


  onDrop(acceptedFiles, rejectedFiles){
    let newFiles = this.props.files.slice()
    acceptedFiles.forEach(file => {
      newFiles.push(file)
    })
     this.props.handleFileSelection(newFiles)
    // this.setState({files: newFiles})

  }

  getFiles(){
    if(this.props.files.length > 0){
     let files = this.props.files.slice()
     return files.map(file => {
        return (
          <div key={files.indexOf(file)} >
          <li><span>{file.name}</span><span id={file.name} onClick={this.removeFile.bind(this)} style={{fontWeight: '400', paddingLeft: '5px', color: '#ef0d4d', cursor: 'pointer'}}>x</span></li>
          </div>
      )
      })
    }
  }

  removeFile(event){
     let newFiles = this.props.files.slice()
     newFiles.forEach(file => {
      if(file.name === event.target.id){
        const index = newFiles.indexOf(file)
        newFiles.splice(index, 1)
      }
     })
     this.props.handleFileSelection(newFiles)

     // this.setState({files: newFiles}) 
  }

  getDescribeField(){
    if(this.props.files.length > 0){
      return (
        <div className="UploadOption__field">
          <TextField style={{width: '100%'}} underlineFocusStyle={{borderColor: '#5abee9'}} hintStyle={{color: '#9e9d9e'}} multiLine={true} floatingLabelText="Upload Description*" hintText="Describe the files you are uploading" onChange={this.props.handleDescriptionChange.bind(this)} value={this.props.description} floatingLabelStyle={{color: 'white'}} textareaStyle={{color: 'white'}} errorText={this.props.getErrorText(this.props.descriptionError, this.props.tabError)} errorStyle={this.props.getErrorStyle(this.props.tabError)} />
        </div>
      )
    }
  }

  getFileList(){
    if(this.props.files.length > 0){
      return(
        <div>
        <div className="TabContainer__header-description UploadOption__header-description"><p>Files uploaded:</p></div>
        {this.getFiles()}
        </div>
      )
    }
  }

  render(){

     return(
      <div >

                    <div className="TabContainer__help-description UploadOption__help-description">If you are uploading a shapefile be sure to upload all the files (.shp, .shx, .dbf and .prj) that comprise the shapefile.</div>
                    <div className="TabContainer__help-description UploadOption__help-description">If you are uploading a geodatabase be sure to zip it before uploading.</div>
                    <Dropzone multiple={true} onDrop={this.onDrop.bind(this)}>
                      {({getRootProps, getInputProps})=> {
                        return (
                          <div {...getRootProps()} style={{height: '200px', padding: '10px', border: '1px dashed white', cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
                            <SvgIcon style={{height: '65px', width: '65px' } }  color={"white"}><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"/></SvgIcon>
                            <div className="UploadOption"><h3>Drag&Drop files here</h3></div>
                            <div className="UploadOption"><h3>or</h3></div>
                            <input {...getInputProps()} />
                            <RaisedButton  label="Browse Files" labelStyle={{color: 'white'}} backgroundColor={"#5abee9"}/>
            
                        </div>)
                      }}
                     
                    </Dropzone>
                      
          
          {this.getFileList()}   
          {this.getDescribeField()}
      </div>

    )
    }

}

UploadOption = connect((state) => ({
}))(UploadOption)
export default UploadOption
