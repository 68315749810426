import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import './index.css';
import * as serviceWorker from './serviceWorker';
import Routes from './routes'
import createStore from './store'
import {loadCss} from 'esri-loader'

loadCss('https://js.arcgis.com/4.13/esri/css/main.css')


const appRoot = document.getElementById("root")
const store = createStore()


ReactDOM.render((
  <Provider store={store}>
    <Routes />
  </Provider>
), appRoot)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
