import {Component} from 'react'
import {PropTypes} from 'prop-types'
// import FeatureLayer from 'esri/layers/FeatureLayer'
import {loadFeatureLayerFromOptions} from '../../../utils/esriLoader'
import {createUniqueValueRendererByCategory} from '../utils/createRenderer'
          
class FeatureServiceLayer extends Component {
  static propTypes = {
    options: PropTypes.shape({
      id: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired
    })
  }

  static defaultProps = {
    options: {}
  }

  constructor(props) {
    super(props)
    this.state = {
      featureLayer: null,
      options: null
    }
  }

  componentDidMount(){

    loadFeatureLayerFromOptions(this.props.options, this.props.options.rendererProps).then((_featureLayer) => {
            this.props.handleAddLayer(_featureLayer, this.props.options.visible)
            this.setState({featureLayer: _featureLayer, options: this.props.options})
    })

  }

  handleError(error) {
    this.props.handleLayerLoadError(this.props.options.id)
  }


  static getDerivedStateFromProps(props, state){
        var newRenderer = createUniqueValueRendererByCategory(props.options.rendererProps, props.options.geometryType)
        if(state.featureLayer){
          props.handleAddLayer(state.featureLayer, props.options.visible)
          return({featureLayer: Object.assign(state.featureLayer, props.options, {renderer: newRenderer}), options: props.options })   
        }
        else{ return null} 

  }

  render() { return null }
}

export default FeatureServiceLayer
