import {Component} from "react"
import _ from "lodash"
import {PropTypes} from 'prop-types'

const eventPropType = PropTypes.shape({
  which: PropTypes.number,
  keyCode: PropTypes.number,
  metaKey: PropTypes.bool,
  ctrlKey: PropTypes.bool,
  shiftKey: PropTypes.bool,
  altKey: PropTypes.bool
})

class KeyboardListener extends Component {
  static propTypes = {
    keyEvent: PropTypes.oneOfType([eventPropType, PropTypes.arrayOf(eventPropType)]).isRequired,
    handler: PropTypes.func.isRequired,
    eventType: PropTypes.oneOf(["keydown", "keyup", "keypress"])
  }

  static defaultProps = {
    eventType: "keydown"
  }

  componentDidMount() {
    const key = this.props.keyEvent
    this.keys = Array.isArray(key) ? key : [key]
    this._onEvent = this.onEvent.bind(this)
    document.addEventListener(this.props.eventType, this._onEvent)
  }

  componentWillUnmount() {
    document.removeEventListener(this.props.eventType, this._onEvent)
  }

  onEvent(e) {
    for (let key of this.keys) {
      if (_.matches(key)(e)) return this.props.handler(e)
    }
  }

  render() {
    return null
  }
}

export default KeyboardListener
