import React from 'react'
import classNames from 'classnames'
// import Icon from '../Icon/Icon'
import {PropTypes} from 'prop-types'
import { isEmpty } from 'lodash'

require('./HelpItem.scss')

const HelpItem = (props) => {
  
  // const {
    // id,
    // visible,
    // onGetDetails,
    // onToggle,
    // icon,
    // children,
    // className,
    // accordian,
    // disabled,
    // title,
    // helpText } = props

  const getClassName = () => classNames('HelpItem', props.className, {
    'HelpItem--visible': props.visible,
    'HelpItem--disabled': props.disabled
  })

  // this goes all the way up to the ListContainer
  const handleToggle = (e) => {
    // do something interal to this component here
    props.onToggle(props.id, e)
  }

  // const handleGetHelpItemDetails = (e) => {
  //   // do something
  //   props.onGetDetails(props.id, e)
  // }

  // const renderVisibilityIcons = () => {
  //   return (props.visible)
  //     ? <Icon className="HelpItem__visibility-icon" name="visibility-on" />
  //     : <Icon className="HelpItem__visibility-icon" name="visibility-off" />
  // }

  const renderAccordianContent = () => {
    return props.accordian.forEach((a, index) => {
      for (var prop in a){
        return (<div key={index}>{prop}: {a[prop]}</div>)
      }
    })
  }

  const hasAccordianContent = () => {
    return !isEmpty(props.accordian)
  }
   
  return (
    <div className={getClassName()} >
      <div className="HelpItem__content">
       <div className="HelpItem__primary-content" onClick={handleToggle}>
       <h3>{props.title}</h3>
         <div className="HelpItem__children">{props.helpText}</div>   
       </div>
      </div >
      { hasAccordianContent() && <div className="HelpItem__accordion">{renderAccordianContent()}</div> }
    </div>
  )}


HelpItem.propTypes = {
  id: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  icon: PropTypes.string
}

HelpItem.defaultProps = {
  onSelect: () => {},
  onToggle: () => {},
  icon: 'pin'
}

export default HelpItem
