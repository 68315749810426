import React, {Component} from 'react'
// import {PropTypes} from 'prop-types'
import classNames from 'classnames'
import {connect} from 'react-redux'
import Tab from '../../Tab/Tab'
import TextField from 'material-ui/TextField'
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
// import DatePicker from 'material-ui/DatePicker';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

require('./ProjectTab.scss')

class ProjectTab extends Component {

  constructor(props) {
    super(props)
    this.state = {
     status: "",
      count: "",
      projectName: "",
      projectStart: {},
      projectEnd: {},
      projectDescription: "",
      targetSpecies: "",
      environmentalClearance: "",
      comments: "" ,
   countError: true,
      statusError: true,
      projectNameError: true,
      projectStartError: false,
      projectEndError: true,
      projectDescriptionError: true,
      tabError: false,
      smScreen: 769,
      projectStartMonth: null,
      projectStartYear: null,
      projectEndMonth: null,
      projectEndYear: null,
      test: "",
    }

 }

  handleStatusClick(event) {
  if(typeof event === "object"){
    if (event.target.innerText === this.state.status){
      // this.setState({status: "", statusError: true})
    }
    else{
      this.setState({status: event.target.innerText, statusError: false})
    }  
  }
  else if(typeof event === "string")
    if(event === this.state.status){
      // this.setState({status: "", statusError: true})
    }
    else{
      this.setState({status: event, statusError: false})
    } 
  } 


  handleCountClick(event) {
    if(typeof event === "object"){
      if (event.target.innerText === this.state.count) {
        // this.setState({count: "", countError: true})
      }
      else if(event.target.innerText === "one") {
        this.setState({count: event.target.innerText, countError: false})

      }
      else{
        this.setState({count: event.target.innerText, countError: false})
      }
    }
    else if(typeof event === "string"){
       if (event === this.state.count) {
        // this.setState({count: "", countError: true})
      }
      else if(event === "one") {
        this.setState({count: event, countError: false})

      }
      else{
        this.setState({count: event, countError: false})
      }     
    }
  }

  handleProjectNameChange(event){
    if(typeof event === "object"){
      (event.target.value.length > 0) ? this.setState({projectName: event.target.value, projectNameError: false}) : this.setState({projectName: event.target.value, projectNameError: true}); 
    }
    else if(typeof event === "string"){ 
     (event.length > 0) ? this.setState({projectName: event, projectNameError: false}) : this.setState({projectName: event, projectNameError: true});  
    }    
  }

  handleProjectEndChange(event, date){
    (this.isDate(date)) ? this.setState({projectEnd: date, projectEndError: false}) : this.setState({projectEnd: date, projectEndError: true}); 

  }

  isDate(date){
    return (typeof date.getDate === 'function')
  }

  handleProjectStartChange(event, date){
    console.log(date);
    (this.isDate(date)) ? this.setState({projectStart: date, projectStartError: false}) : this.setState({projectStart: date, projectStartError: true}); 
  
  }

  handleProjectDescriptionChange(event){

    if(typeof event === "object"){
      (event.target.value.length > 0) ? this.setState({projectDescription: event.target.value, projectDescriptionError: false}) : this.setState({projectDescription: event.target.value, projectDescriptionError: true});    
   }
    else if(typeof event === "string"){ 
       (event.length > 0) ? this.setState({projectDescription: event, projectDescriptionError: false}) : this.setState({projectDescription: event, projectDescriptionError: true});    
    }
   
  }

  handleTargetSpeciesChange(event){
    (typeof event === "object") ? this.setState({targetSpecies: event.target.value}) : this.setState({targetSpecies: event});    
  }

  handleEnvironmentalClearanceChange(event){
    (typeof event === "object") ? this.setState({environmentalClearance: event.target.value}) : this.setState({environmentalClearance: event});    
  }

  handleCommentsChange(event){
     (typeof event === "object") ? this.setState({comments: event.target.value}) : this.setState({comments: event});    
  }


  getCountClassName (name, prop) {
    return classNames("TabContainer__toggle-item", {
       "selected" : (name === prop),

       "disabled" : (name === "many" && this.isTabletOrPhone())
    })
  }

  isTabletOrPhone(){
    if(navigator.userAgent.includes("iPad") || navigator.userAgent.includes("Android") || navigator.userAgent.includes("iPhone") ){
      return true
    }
    else{
      return false
    }
  }  

  getSingleProjectClassNames(value){
    return classNames("TabContainer__field", {
      "ProjectTab__hidden" : ( (this.state.status === "") || (this.state.count !== "one" && this.state.count !== "ongoing") || (value === "environmentalClearance" && (this.state.status !== "planned")) || (this.state.status !== "ongoing" && value === "projectStart")),
      "ProjectTab__date-picker-item" : (value === "projectStart")
    })
  }

  // getAgreementText(){
  //   if(this.state.status === "planned"){
  //     return "Please check that the file being uploaded contains fields for project name, year, descriptions, target species, and environmental clearance."
  //   }
  //   else{
  //     return "Please check that the file being uploaded contains fields for project name, year, descriptions, and target species."
  //   }
  // }

  getSketchText(){
    if(this.isTabletOrPhone()){
      return "Want to submit multiple projects?  Revisit this site from a computer."
    }
    else{
      return "The sketch tool in the next step can only be used if a single project is being submitted."
    }
  }

  getManyProjectsClassNames(){
    return classNames({
      "ProjectTab__hidden" : !(this.state.count === "many" && this.state.status !== ""),
      "TabContainer__field-row" : (this.state.count === "many" && this.state.status !== "")
    })
  } 

  componentDidMount(){
    this.handleProjectNameChange(this.props.projectName)
    this.handleCountClick(this.props.count)
    this.handleStatusClick(this.props.status)
    this.handleProjectStartChange(null, this.props.projectStart)
    this.handleProjectEndChange(null, this.props.projectEnd)
    this.handleProjectDescriptionChange(this.props.projectDescription)
    this.handleCommentsChange(this.props.comments)
    this.handleEnvironmentalClearanceChange(this.props.environmentalClearance)
    this.handleTargetSpeciesChange(this.props.targetSpecies)
    if (this.props.projectEnd instanceof Date) {
      this.handleEndMonthChange(null, null, this.props.projectEnd.getMonth() + 1)
      this.handleEndYearChange(null, null, this.props.projectEnd.getFullYear())
    } 
    if (this.props.projectStart instanceof Date) {
      this.handleStartMonthChange(null, null, this.props.projectStart.getMonth() + 1)
      this.handleStartYearChange(null, null, this.props.projectStart.getFullYear())
    } 

  }

  projectTabError(){
    if(this.state.countError || this.state.statusError){
      return true
    }
    else if(this.state.count === "one" && (this.state.projectNameError || this.state.projectEndError || this.state.projectDescriptionError || ((this.state.status === "ongoing" && this.state.projectStartError)))){
       return true
    }
    else{
      return false
    }

  }

    handlePrev(){
      this.props.handlePrev(this.state)
    }

    handleNext(){
      if(this.projectTabError()){
        this.setState({tabError: true})
      }
      else{
        //go to next (from parents)
        this.props.handleNext(this.state)
      }
    }

   getProjectEndMonthLabel(){
    return (this.state.status !== "completed") ? "Estimated Completion Month*" : "Completion Month*"
   } 

     getProjectEndYearLabel(){
    return (this.state.status !== "completed") ? "Estimated Completion Year*" : "Completion Year*"
   } 

   getProjectEndMonthHint(){
    return (this.state.status !== "completed") ? "Expected Completion Month" : "Month Completed"
   } 

     getProjectEndYearHint(){
    return (this.state.status !== "completed") ? "Expected Completion Year" : "Year Completed"
   } 

  getAgreementText(){
    if(this.state.status === "planned" && this.state.count === "many"){
      return "On the next step, please check that the file being uploaded contains fields for project name, start date, expected completion date, project description, target species, and environmental clearance."
    }
    else if(this.state.status === "completed" && this.state.count === "many"){
      return "On the step, please check that the file being uploaded contain fields for project name, year completed, project description, and target species."
    }
    else if(this.state.status === "ongoing" && this.state.count === "many")
      return "On the step, please check that the file being uploaded contain fields for project name, start date, expected completion date, project description, and target species."
  }



  getMinDate(value){
    if(this.state.status === "ongoing" && value === "projectEnd"){
      return this.state.projectStart
    }
    else{
      return null
    }
  }

  handleStartMonthChange(event, index, value){
    this.setState({projectStartMonth: value})
    if(this.state.projectStartYear !== null){
      this.handleProjectStartChange(event, new Date(value + " 1," + this.state.projectStartYear))
    }
  }

  handleStartYearChange(event, index, value){
    this.setState({projectStartYear: value})
    if(this.state.projectStartMonth !== null){
      this.handleProjectStartChange(event, new Date(this.state.projectStartMonth + " 1," +  value))
    }
  }

  handleEndMonthChange(event, index, value){
    this.setState({projectEndMonth: value})
    if(this.state.projectEndYear !== null){
      this.handleProjectEndChange(event, new Date(value + " 1," + this.state.projectEndYear))
    }
  }

  handleEndYearChange(event, index, value){
    this.setState({projectEndYear: value})
    if(this.state.projectEndMonth !== null){
      this.handleProjectEndChange(event, new Date(this.state.projectEndMonth + " 1," +  value))
    }
  }

  getMonthList(className, labelText, stateValue, handleChange, errorText, hintText){

    return(
      <SelectField underlineFocusStyle={{borderColor: '#5abee9'}} labelStyle={{color: 'white'}}  hintStyle={{color: '#9e9d9e'}} inputStyle={{color: '#9e9d9e'}}  floatingLabelFixed={true} hintText={hintText} className={this.getSingleProjectClassNames(className)}  floatingLabelText={labelText} value={stateValue} onChange={handleChange} floatingLabelStyle={{color: 'white'}} errorText={this.props.getErrorText(errorText, this.state.tabError)} errorStyle={this.props.getErrorStyle(errorText, this.state.tabError)}>
   
            <MenuItem value={1} primaryText="Jan" />
              <MenuItem value={2} primaryText="Feb" />
              <MenuItem value={3} primaryText="Mar" />
              <MenuItem value={4} primaryText="Apr" />
              <MenuItem value={5} primaryText="May" />
              <MenuItem value={6} primaryText="Jun" />
              <MenuItem value={7} primaryText="Jul" />
              <MenuItem value={8} primaryText="Aug" />
              <MenuItem value={9} primaryText="Sep" />
              <MenuItem value={10} primaryText="Oct" />
              <MenuItem value={11} primaryText="Nov" />
              <MenuItem value={12} primaryText="Dec" />
          </SelectField>
    )
  }

    getYearList(className, labelText, stateValue, handleChange, errorText, hintText){
    return(
      <SelectField underlineFocusStyle={{borderColor: '#5abee9'}} labelStyle={{color: 'white'}}  hintStyle={{color: '#9e9d9e'}} inputStyle={{color: '#9e9d9e'}} floatingLabelFixed={true} hintText={hintText} className={this.getSingleProjectClassNames(className)}  floatingLabelText={labelText} value={stateValue} onChange={handleChange} floatingLabelStyle={{color: 'white'}} errorText={this.props.getErrorText(errorText, this.state.tabError)} errorStyle={this.props.getErrorStyle(errorText, this.state.tabError)}>
          <MenuItem value={2007} primaryText="2007" />
          <MenuItem value={2008} primaryText="2008" />
          <MenuItem value={2009} primaryText="2009" />
          <MenuItem value={2010} primaryText="2010" />
          <MenuItem value={2011} primaryText="2011" />
          <MenuItem value={2012} primaryText="2012" />
          <MenuItem value={2013} primaryText="2013" />
          <MenuItem value={2014} primaryText="2014" />
          <MenuItem value={2015} primaryText="2015" />
          <MenuItem value={2016} primaryText="2016" />
          <MenuItem value={2017} primaryText="2017" />
          <MenuItem value={2018} primaryText="2018" />
          <MenuItem value={2019} primaryText="2019" />
          <MenuItem value={2020} primaryText="2020" />
          <MenuItem value={2021} primaryText="2021" />
          <MenuItem value={2022} primaryText="2022" />
          <MenuItem value={2023} primaryText="2023" />
          <MenuItem value={2024} primaryText="2024" />
      </SelectField>
    )
  }

  render(){
    return (
        <Tab>
        <div className="TabContainer__group">
          <h1>Treatment</h1> 
         <div className="TabContainer__header-description"><p>Describe your vegetation treatment project(s)</p></div>
          <div className="TabContainer__field ProjectTab__field">
            How many projects are you submitting?*
            <div className="TabContainer__toggle-description">{this.getSketchText()}</div>
            <div className="TabContainer__toggle">
              <div className={this.getCountClassName("one", this.state.count)} onClick={this.handleCountClick.bind(this)}>one</div>
              <div className={this.getCountClassName("many", this.state.count)} onClick={this.handleCountClick.bind(this)}>many</div>
            </div>
            <div className="TabContainer__toggle-error">{this.props.getToggleErrorText(this.state.countError, this.state.tabError)}</div>
          </div>

           <div className="TabContainer__field ProjectTab__field">
            Status*
            <div className="TabContainer__toggle">
              <div className={this.getCountClassName("planned", this.state.status)} onClick={this.handleStatusClick.bind(this)}>planned</div>
              <div className={this.getCountClassName("ongoing", this.state.status)} onClick={this.handleStatusClick.bind(this)}>ongoing</div>
             <div className={this.getCountClassName("completed", this.state.status)} onClick={this.handleStatusClick.bind(this)}>completed</div>
             
            </div>
            <div className="TabContainer__toggle-error">{this.props.getToggleErrorText(this.state.statusError, this.state.tabError)}</div>
            <span className="TabContainer__toggle-description">{this.getAgreementText()}</span>
          </div>


        <TextField className={this.getSingleProjectClassNames(this.state.projectName)} type="text" hintText="Name of project" underlineFocusStyle={{borderColor: '#5abee9'}} hintStyle={{color: '#9e9d9e'}} floatingLabelText="Project Name*" onChange={this.handleProjectNameChange.bind(this)} value={this.state.projectName} floatingLabelStyle={{color: 'white'}} inputStyle={{color: 'white'}} errorText={this.props.getErrorText(this.state.projectNameError, this.state.tabError)} errorStyle={this.props.getErrorStyle(this.state.projectNameError, this.state.tabError)} />
        {}
        {}
         <div className="ProjectTab__date-picker-group">
          {this.getMonthList("projectStart", "Start Month*", this.state.projectStartMonth, this.handleStartMonthChange.bind(this), this.state.projectStartError, "Month the Project Started" )}
          {this.getYearList("projectStart", "Start Year*", this.state.projectStartYear, this.handleStartYearChange.bind(this), this.state.projectStartError, "Year the Project Started" )}
        </div>
         <div className="ProjectTab__date-picker-group">
          {this.getMonthList("projectEnd", this.getProjectEndMonthLabel() , this.state.projectEndMonth, this.handleEndMonthChange.bind(this), this.state.projectEndError, this.getProjectEndMonthHint() )}
          {this.getYearList("projectEnd", this.getProjectEndYearLabel(), this.state.projectEndYear, this.handleEndYearChange.bind(this), this.state.projectEndError, this.getProjectEndYearHint() )}
        </div>
        <TextField className={this.getSingleProjectClassNames(this.state.projectDescription)} type="text" hintText="Description of the activity (eg: burn 600 acres to improve forage, prescribed fire, commercial thinning)" underlineFocusStyle={{borderColor: '#5abee9'}} hintStyle={{color: '#9e9d9e'}} floatingLabelText="Project Description*" value={this.state.projectDescription} onChange={this.handleProjectDescriptionChange.bind(this)} floatingLabelStyle={{color: 'white'}} inputStyle={{color: 'white'}} errorText={this.props.getErrorText(this.state.projectDescriptionError, this.state.tabError)} errorStyle={this.props.getErrorStyle(this.state.projectDescriptionError, this.state.tabError)} />
        <TextField className={this.getSingleProjectClassNames("na")} type="text" hintText="Common name of species the treatment is targeting (eg: ponderosa, juniper, salt cedar)" underlineFocusStyle={{borderColor: '#5abee9'}} hintStyle={{color: '#9e9d9e'}} floatingLabelText="Target Species" value={this.state.targetSpecies} onChange={this.handleTargetSpeciesChange.bind(this)} floatingLabelStyle={{color: 'white'}} inputStyle={{color: 'white'}} />
        <TextField className={this.getSingleProjectClassNames("environmentalClearance")} type="text" hintText="Clearance status (eg: Pending - NEPA decision expected in 2018 or Completed - [name of NEPA document])" underlineFocusStyle={{borderColor: '#5abee9'}} hintStyle={{color: '#9e9d9e'}} floatingLabelText="Environmental Clearance" value={this.state.environmentalClearance} onChange={this.handleEnvironmentalClearanceChange.bind(this)} floatingLabelStyle={{color: 'white'}} inputStyle={{color: 'white'}} />
        <TextField className={this.getSingleProjectClassNames("na")} type="text" hintText="Any additional information we should know about the project " underlineFocusStyle={{borderColor: '#5abee9'}} hintStyle={{color: '#9e9d9e'}} floatingLabelText="Comments" value={this.state.comments} onChange={this.handleCommentsChange.bind(this)}   floatingLabelStyle={{color: 'white'}} inputStyle={{color: 'white'}} /> 
        <div className="TabContainer__group-button">
          <FlatButton labelStyle={{color: "white"}} label="Back" disabled={false} onClick={this.handlePrev.bind(this)} style={{marginRight: 12}}/>
          <RaisedButton label="Next" labelStyle={{color: 'white'}} backgroundColor={"#efce16"} onClick={this.handleNext.bind(this)}/>
        </div>
        </div>
        </Tab>

      )
    }

}


ProjectTab = connect((state) => ({

}))(ProjectTab)
export default ProjectTab
