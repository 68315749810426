// import { browserHistory } from 'react-router-dom';
// import {updateActiveGrowls} from './growlActions'

export const openModal = (id, props) => async (dispatch) => {
  console.log("open modal")

  dispatch({
    type: "OPEN_MODAL",
    payload: {id, props}
  })
}

// export const updateWindowWidth = (width) => async(dispatch) => {
//     dispatch({
//     type: "UPDATE_WINDOW_WIDTH",
//     payload: {id, props}
//   })
// }

export const closeModal = (id) => async (dispatch) => {
  dispatch({type: "CLOSE_MODAL", payload: id})
}

export const openDrawer = (id, props) => async (dispatch) => {
  dispatch({
    type: "OPEN_DRAWER",
    payload: {id, props}
  })
}

export const updateSubmissionModal = (progress, uiDescription) => async(dispatch) => {
  dispatch({
    type: "UPDATE_SUBMISSION_PROPS",
    payload: {
        props: {progress, uiDescription}
    }
  })
}

export const closeDrawer = (id) => async (dispatch) => {
  dispatch({type: "CLOSE_DRAWER", payload: id})
}

// export const updateBrowserHistory = (path) => async (dispatch) =>{
//   browserHistory.push(path)
// }