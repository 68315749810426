import {loadModules} from 'esri-loader'

export const loadExtent = (xMin, xMax, yMin, yMax) => {
	return     loadModules(['esri/geometry/Geometry']).then(([Extent]) => {
		  return new Extent({ 
		    xmin: xMin,
		    ymin: yMin,
		    xmax: xMax,
		    ymax: yMax,
		    spatialReference: {
		      wkid: 3857
		    },
		    type: "extent"
		  })
      
    })
}




export const loadGraphic = (geom, attr) => {
	return     loadModules(['esri/Graphic']).then(([Graphic]) => {

		return new Graphic({ 
    		geometry: geom,
    		symbol: attr
  })
      
    })
}

export const loadGraphicFromGeometry = (geom) => {
	return     loadModules(['esri/Graphic']).then(([Graphic]) => {

		return new Graphic({ 
    		geometry: geom,
    		symbol: {
    			type: 'simple-fill'
    		}
  })
      
    })
}

export const loadFeatureLayer = (url) => {
	return  loadModules(['esri/layers/FeatureLayer']).then(([FeatureLayer]) => {
		return   new FeatureLayer({url: url})	

      
    })
}

export const loadFeatureLayerFromOptions = (options, renderer) => {
	return     loadModules(['esri/layers/FeatureLayer']).then(([FeatureLayer]) => {
		return   new FeatureLayer({options, renderer})	

      
    })
}


export const loadZoom = (options) => {
	return     loadModules(['esri/widgets/Zoom']).then(([Zoom]) => {
		return  new Zoom(options)
    })
}


export const loadBasemapToggle = (view, next) => {
	return     loadModules(['esri/widgets/BasemapToggle']).then(([BasemapToggle]) => {
		return new BasemapToggle({
	      view: view,  
	      nextBasemap: next  
	    })
    })
}

export const loadSimpleFillSymbol= (obj) => {
	return     loadModules(['esri/symbols/SimpleFillSymbol']).then(([SimpleFillSymbol]) => {
		console.log(obj)
		return  new SimpleFillSymbol(obj) 
    })
}


export const loadWebStyleSymbol= (obj) => {
	return     loadModules(['esri/symbols/WebStyleSymbol']).then(([WebStyleSymbol]) => {
		return  new WebStyleSymbol(obj) 
    })
}


export const loadSimpleRenderer= (obj) => {
	return     loadModules(['esri/renderers/SimpleRenderer']).then(([SimpleRenderer]) => {
		return  new SimpleRenderer(obj) 
    })
}

export const loadSimpleMarkerSymbol = (s) => {
	return     loadModules(['esri/symbols/SimpleMarkerSymbol']).then(([SimpleMarkerSymbol]) => {
		return  new SimpleMarkerSymbol(s) 
    })
}

export const loadUniqueValueRenderer = (field, symbol, values, visualVariables) => {
	return loadModules(['esri/renderers/UniqueValueRenderer']).then(([UniqueValueRenderer]) => {
		return new UniqueValueRenderer({
			type: "unique-value",
		    field: field,
		    defaultSymbol: symbol,
		    uniqueValueInfos: values,
		    visualVariables: visualVariables
		  })
	})
}

// export const loadMap= (mapOptions) => {
// 	return loadModules(['esri/Map']).then(([EsriMap]) => {
// 		return  new EsriMap(mapOptions)
//     })
// }

// export const loadPoint= (x, y, spatialReference) => {
// 	return     loadModules(['esri/geometry/Point']).then(([Point]) => {
// 		return new Point({
//         x: x,
//         y: y,
//         spatialReference: spatialReference,
//       })
//     })
// }


export const loadBasemaps = () => {
	return     loadModules(['esri/Basemap']).then(([Basemap]) => {

		let topo = new Basemap.fromId('topo-vector')
		let aerial = new Basemap.fromId('satellite')
		return  {topo: topo, aerial: aerial}

      
    })
}


export const loadMapView= (element, basemap, x, y, sr, next, toggleLocation) => {

	return loadModules(['esri/Map', 'esri/views/MapView', 'esri/widgets/BasemapToggle', 'esri/geometry/Point', 'esri/layers/GraphicsLayer']).then(([Map, MapView, BasemapToggle, Point, GraphicsLayer]) => {

	  let point = new Point({x: x, y: y, spatialReference: sr})

	  let graphicsLayer = new GraphicsLayer()
	 
	  let config = Object.assign({}, {
	    mapOptions: {
	      basemap: basemap,
  	      layers: [graphicsLayer]
	    },
	    viewOptions: {
	      ui: { components: ['attribution']},
	      center: point,
	      zoom: 7,
	      constraints: {
	        rotationEnabled: false
	      }
	    }
	  })


		let esriMap = new Map(config.mapOptions)

		let mapView = new MapView({
	      container: element,
	      map: esriMap,
	      ...config.viewOptions,
	    })

		let toggle = new BasemapToggle({
	      view: mapView,  
	      nextBasemap: next  
	    })


	    mapView.ui.add(toggle, toggleLocation)


	    return mapView
    })
}


export const loadSketchViewModel = (view, polySymbol, layer) => {
	return     loadModules(['esri/widgets/Sketch/SketchViewModel',]).then(([SketchViewModel]) => {
		const viewModel = new SketchViewModel({view: view, polygonSymbol: polySymbol, layer: layer})
		return viewModel
	})

		
}




