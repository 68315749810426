// TODO: fix "props disappearing" and create smoother animation

import React, {Component} from "react"
import {connect} from "react-redux"
import classNames from "classnames"
import ReactModal from "react-modal"
import * as uiActions from "../../../actions/uiActions"
// import NavLink from '../NavLink/NavLink'
import {NavLink} from 'react-router-dom'
import {PropTypes} from 'prop-types'

require('./Modal.scss')
class Modal extends Component {
  static propTypes = {
    ...Modal.propTypes,
    id: PropTypes.string.isRequired
  }

  static defaultProps = {
    onRequestClose: () => {}
  }

  getClassName() {
    return classNames("Modal", this.props.className)
  }

  componentWillUnmount() {
    if (this.props.activeModal === this.props.id) this.close()
  }

  preventBackdropClick(e) {
    e.stopPropagation()
  }

  close() {
    this.props.dispatch(uiActions.closeModal(this.props.id))
    this.props.onRequestClose()
  }

  render() {
    let {id} = this.props
    let isOpen = (this.props.activeModal === id)
    let toUrl = "/"
    return (
      <ReactModal {...this.props} onRequestClose={this.close.bind(this)} isOpen={isOpen} closeTimeoutMS={250}>
        <div className={this.getClassName()} onClick={this.close.bind(this)}>
          <div className="Modal__dialog" onClick={this.preventBackdropClick.bind(this)}>
            <div className="Modal__content">
              <div className="Modal__btn-close" data-dismiss="modal" aria-label="Close" onClick={this.close.bind(this)}>
                <NavLink to={toUrl}>&times;</NavLink>
              </div>
              {this.props.children}
            </div>
          </div>
        </div>
      </ReactModal>
    )
  }
}

Modal = connect(state => ({
  ...state.ui.get('modalProps'),
  activeModal: state.ui.get('activeModal'),
  contributeType: state.ui.get('contributeType')
}), null, null, {forwardRef: true})(Modal)
export default Modal

