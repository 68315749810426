import React, {Component} from 'react'
// import {connect} from 'react-redux'
import classNames from 'classnames'
import Icon from '../Icon/Icon'
// import {PropTypes} from 'prop-types'

require('./OverlaySpinner.scss')

class OverlaySpinner extends Component {
  getClassNames() {
    return classNames('OverlaySpinner', this.props.className)
  }

  render() {
    return(
      <div className={this.getClassNames()}>
        <Icon className="OverlaySpinner__icon" name="loader" size={72}/>
      </div>
    )
  }
}

export default OverlaySpinner