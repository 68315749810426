import AWS from 'aws-sdk'

export const hasCredentials = () => {
    var creds = AWS.config.credentials
    var promise = creds.getPromise()
    return promise.then(function(){
        return null
      }, function(err) {
        return err
      })


}

export const getCredentials = () => {
  var creds = new AWS.CognitoIdentityCredentials({
   IdentityPoolId: 'us-east-1:1d6a3499-25ef-48a1-acd5-c8b32d53ba6f', //from federated users pool in cognito
  });
  AWS.config.region = 'us-east-1'
  AWS.config.credentials = creds;
  return creds
}