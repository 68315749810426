import React, {Component} from 'react'
import {connect} from 'react-redux'
import Growl from 'react-notification-system'
import {isPlainObject} from 'lodash'
import {PropTypes} from 'prop-types'

class GrowlContainer extends Component {
  static propTypes = {
    active: PropTypes.array,
    growls: PropTypes.object
  }

  static defaultProps = {
    active: [],
    growls: {},

  }

  constructor(props) {
    super(props)
    this.state = {
      lastUpdated: null
    }

    this.growlSystem = null
  }

  componentDidMount() {
    this.growlSystem = this.refs.growlSystem
  }



  componentWillReceiveProps(nextProps) {

    if (this.props.lastUpdated == null || (this.props.lastUpdated !== nextProps.lastUpdated)) {

      nextProps.active.forEach((growl, index) => {
        (isPlainObject(growl))
          ? this.growlSystem.addNotification(growl)
          : this.growlSystem.addNotification(nextProps.growls[growl]) 
      })
    }
    else if(nextProps.active.length === 0){

      this.growlSystem.clearNotifications()
    }
  }


  render() {
    return <Growl ref="growlSystem"/>
  }
}

//TODO: memoize dem selectors dog
GrowlContainer = connect(state => ({
  lastUpdated: state.growls.get('lastUpdated'),
  active: state.growls.get('active').toJS(),
  growls: state.growls.get('growls').toJS()
}))(GrowlContainer)
export default GrowlContainer
