import React, {Component} from 'react'
// import {PropTypes} from 'prop-types'
import classNames from 'classnames'
import {connect} from 'react-redux'
import Tab from '../../Tab/Tab'
// import TextField from 'material-ui/TextField'
import YourArea from './YourArea/YourArea'
import UploadOption from './UploadOption/UploadOption'
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import {resetSketch, setSketching, stopSketching} from '../../../../actions/esri/sketchActions'
import {isEmpty} from  '../../../utils/helpers.js'

require('./AreaTab.scss')
class AreaTab extends Component {


    constructor(props) {
      super(props)
      this.state = {
        area: "",
        // fileActual: null,
        // fileName: "",
        files: [],
        description: "",
        graphic: {},
        sketching: false,
        areaError: true,
        sketchError: true,
        descriptionError: true,
        tabError: false,
        smScreen: 767
      };

   } 


 resetSketch(){
  this.props.dispatch(resetSketch())
 }

handleSketchButton(){

  if(!this.state.sketching){
    this.props.dispatch(setSketching(true))
  }
  else{
    if(isEmpty(this.state.graphic)){
      this.props.dispatch(stopSketching())
    }
  }
} 


  renderAreaType(){
    if(this.state.area === "upload"){
      return <UploadOption count={this.props.count} status={this.props.status} {...this.state} handleFileSelection={this.handleFileSelection.bind(this)} getErrorStyle={this.props.getErrorStyle.bind(this)} getErrorText={this.props.getErrorText.bind(this)} handleDescriptionChange={this.handleDescriptionChange.bind(this)} />
    }

    else if(this.state.area === "sketch"){

      return <YourArea isEmpty={isEmpty} getToggleErrorText={this.props.getToggleErrorText.bind(this)} {...this.state} handleSketchButton={this.handleSketchButton.bind(this)} resetSketch={this.resetSketch.bind(this)}/>
    }
 
  }
  handleFileSelection(uploadedFiles) {
    this.setState({files: uploadedFiles})
    // this.setState({fileActual: event.target.files[0], fileName: event.target.files[0].name})
  }

  handleDescriptionChange(event) {
    if(typeof event === "object"){
      (event.target.value.length > 0) ? this.setState({description: event.target.value, descriptionError: false}) : this.setState({description: event.target.value, descriptionError: true}); 

    }
    else if(typeof event === "string"){
    (event.length > 0) ? this.setState({description: event, descriptionError: false}) : this.setState({description: event, descriptionError: true}); 

    }
  }

  switchToSketch(){
    // this.props.isSketchArea()
    if(this.state.area === "sketch"){
      // this.setState({area: "", areaError: true})
    }else{
      this.setState({area: "sketch", areaError: false})
    }
  }

  switchToUpload(){
    // TO DO: reset sketch and set sketching to false

    if(this.state.area === "upload"){
      // this.setState({area: "", areaError: true})
    }
    else{
      this.props.dispatch(stopSketching())
      this.setState({area: "upload", areaError: false})
    }
  }

  getCountClassName (name, prop) {
    return classNames("TabContainer__toggle-item", {
       "selected" : (name === prop),
       "disabled" : (name === "upload" && this.isTabletOrPhone())
    })
  }  

  isTabletOrPhone(){
    if(navigator.userAgent.includes("iPad") || navigator.userAgent.includes("Android") || navigator.userAgent.includes("iPhone") ){
      return true
    }
    else{
      return false
    }
  } 

  getSketchText(){
  if(this.isTabletOrPhone()){
    return "Want to submit multiple projects?  Revisit this site from a computer."
  }
}

  renderBothOptions(){
      return (
        <div className="TabContainer__field">
          How would you like to define the extent of your project area?
          <div className="TabContainer__toggle-description">{this.getSketchText()}</div>
          <div className="TabContainer__toggle">
              <div className={this.getCountClassName("sketch", this.state.area)}  onClick={this.switchToSketch.bind(this)}>sketch</div>
              <div className={this.getCountClassName("upload", this.state.area)}  onClick={this.switchToUpload.bind(this)}>upload</div>
          </div>
          <div className="TabContainer__toggle-error">{this.props.getToggleErrorText(this.state.areaError, this.state.tabError)}</div>  
        </div>
      )
  }

  handlePrev(){
    this.props.handlePrev(this.state)
  }

  handleNext(){
    if(this.areaTabError()){
      this.setState({tabError: true})
    }
    else{
      //go to next (from parents)
      this.props.handleNext(this.state)
    }
  }


  areaTabError(){
   return ((this.state.areaError && this.state.area === "") || ( this.state.area === "upload" && ( this.state.descriptionError || this.state.files.length === 0)) || ( this.state.sketchError && this.state.area === "sketch") )
  }

  renderGreeting(){
    if(this.props.count === "many"){
        return(
          <div>
          <h1>Area Upload</h1>
          <div className="TabContainer__header-description"><p>Upload files outlining the extent of your projects</p></div>
          </div>
        )
    }else{
    return (
      <div>
      <h1>Area Extent</h1>
      <div className="TabContainer__header-description"><p>Contribute the area of your project</p></div>
      </div>
    )
    }

  }

  renderOptions(){
    if(this.props.count === "one"){
      return this.renderBothOptions()
    }
    else{

    }
  }

  componentDidMount(){
    this.setState({files: this.props.files, area: this.props.area, graphic: this.props.graphic, sketching: this.props.sketching})
    this.handleDescriptionChange(this.props.description)
    if(this.props.count !== "one"){


      this.switchToUpload()
    }

    if(isEmpty(this.props.graphic)){
      this.setState({sketchError: true})
    }
    else{
      this.setState({sketchError: false}) 
    }
  }


  static getDerivedStateFromProps(props, state){

    let sketchError = false

    if(isEmpty(props.graphic)){
      sketchError = true
    }


    return ({graphic: props.graphic, sketching: props.sketching, sketchError: sketchError})


  }

  render(){
    return (
      <Tab {...this.props}>
        <div className="TabContainer__group">


          {this.renderGreeting()}
          {this.renderOptions()}
          {this.renderAreaType()} 
          </div>
          <div className="TabContainer__group-button">
            <FlatButton labelStyle={{color: "white"}} label="Back" disabled={false} onClick={this.handlePrev.bind(this)} style={{marginRight: 12}}/>
            <RaisedButton label="Next" labelStyle={{color: 'white'}} backgroundColor={"#efce16"} onClick={this.handleNext.bind(this)}/>
        </div>
      </Tab>
      )
    }

}

AreaTab = connect((state) => ({
}))(AreaTab)
export default AreaTab
