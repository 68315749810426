import {handleActions} from "redux-actions"
// import Immutable from "immutable"

let defaultState = {
  resetForm: false,
  formError: false,
  submitComplete: false,
}

export default handleActions({
  RESET_FORM_OBJECT: (state,action) => {

    let _newState = Object.assign({}, state);
    _newState.resetForm = action.payload
    return _newState
  },

  CHANGE_FORM_ERROR: (state,action) => {

    let _newState = Object.assign({}, state);
    _newState.formError = action.payload
    return _newState
  },


  CHANGE_SUBMIT_COMPLETE: (state,action) => {

    let _newState = Object.assign({}, state);
    _newState.submitComplete = action.payload
    return _newState
  },

}, defaultState)
