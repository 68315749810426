import {handleActions} from "redux-actions"

let defaultState = {
  foo: "nothing to see here..."
}

export default handleActions({
  DEMO_UPDATE: (state, action) => {
    let _newState = Object.assign({}, state);
    _newState.foo = action.payload;
    
    return _newState
  }
}, defaultState)
