import React, {Component} from 'react'
import {PropTypes} from 'prop-types'
import {connect} from 'react-redux'
// import classNames from 'classnames'
import Group from '../../presentational/Group/Group'
import Item from '../../presentational/Item/Item'
import {
  toggleItem,
  toggleGroup} from '../../../actions/esri/esriMapActions'
import {updateActiveGrowls} from '../../../actions/growlActions'


require('./ListContainer.scss')

// const MAP_TYPE = 'esriMap'

class ListContainer extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired
  }

  static defaultProps = {
    data: {}
  }

  // constructor(props) {
  //   super(props)
  // }

  onItemToggle(id, e) {
    this.props.dispatch(toggleItem(id))

  }

  componentDidMount(){

  }

  onGroupToggle(id, e) {
    this.props.dispatch(toggleGroup(id))

    // Another growl example
    // this.props.dispatch(updateActiveGrowls('group-warning'))
  }

  onGetDetails(id, e){

      this.props.dispatch(updateActiveGrowls({
        title: 'Layer details coming soon!',
        message: 'Thanks for your patience.',
        autoDismiss: 2, 
        level: 'info',
        position: 'tc'
      }))
    
    // this.props.dispatch(getItemDetails(id))
  }

  renderItems(items) {
    const {items: _items} = this.props
    return items.map((itemId) => {
      return _items[itemId].listVisible && <Item key={itemId} 
        id={itemId} 
        {..._items[itemId].options} 
        onToggle={this.onItemToggle.bind(this)}
        onGetDetails={this.onGetDetails.bind(this)}>
          {_items[itemId].options.name}
      </Item>
    }, this)
  }

  getGroupArray(groups) {
    //create unique set of groups
    let groupSet = new Set()

    for (let group in groups) {
      groups[group].listVisible && groupSet.add(group)
    }

    return Array.from(groupSet)
  }

  renderGroups(groups){
    return groups.map((group) => {
      return group.listVisible && <Group key={group.id} 
        {...group}
        filterValue={this.props.filterValue}
        onToggle={this.onGroupToggle.bind(this)}>
          {!!group.items.length && this.renderItems(group.items)}
      </Group>
    })
  }

  renderEmptyMessage() {
    return <div className="List__empty-message">Try again, ace.</div>
  }


  render() {
    const {groups, filterValue} = this.props

    return (
      <div className="List">
        {!!groups.length && this.renderGroups(groups)}
        {filterValue && !this.getGroupArray(groups).length && this.renderEmptyMessage()}
      </div>
    )
  }
}


ListContainer = connect(state => {
  //TODO: turn this into a memoized selector
  const getGroups = (state) => {
    return state.esriMap.get('results')
      .map(groupId => state.esriMap.getIn(['entities', 'groups', `${groupId}`]))
      .toJS()
  }

  return {
    groups: getGroups(state),
    items: state.esriMap.getIn(['entities', 'items']).toJS(),
    resetForm: state.form.resetForm
  }
})(ListContainer)
export default ListContainer
