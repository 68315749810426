import AWS from 'aws-sdk'
// import {hasCredentials, getCredentials} from './credentials'
// import {sendEmail, buildAdminReminderEmail} from '../aws/ses'



export const putUploadEntry = (form) => {
  var params = getUploadEntryParams(form)
  if(form.area === "sketch"){
    params = getSketchEntryParams(form)
  }
  var dynamodb = new AWS.DynamoDB({apiVersion: '2012-08-10'});

  return new Promise(function(resolve, reject){
     dynamodb.putItem(params, function(err, data) {
       if (err) {
        reject(err)
       }
       else{
        resolve(data)
       }              
     });
  })
}

const getUploadEntryParams = (form) => {
  return {
    Item: {
     "NAME": {
       S: form.name
      }, 
     "AGENCY": {
       S: form.agency
      }, 
     "EMAIL": {
       S: form.email
      },
      "PHONE": {
       S: form.phone
      },
      "COUNT": {
       S: form.count
      },
      "SUBMISSION_TYPE": {
       S: form.area
      },
      "PROJECT_STATUS": {
       S: form.status
      },
      "FILE_DESCRIPTION": {
       S: form.description
      },
      "SUBMISSION": {
       S: form.submission.toString()
      },
      "ID" : {
        S: form.email+ "_" + form.submission.getTime().toString()
      },
      "FILE_URL": {
       S: form.url
      },
      "PROJECT_NAME": {
       S: form.projectName
      },
      "PROJECT_START": {
        S: form.projectStart.toString()
      },
      "PROJECT_END": {
       S: form.projectEnd.toString()
      },
      "PROJECT_DESCRIPTION": {
       S: form.projectDescription
      },
      "TARGET_SPECIES": {
       S: form.targetSpecies
      },
      "ENV_CLEARANCE": {
       S: form.environmentalClearance
      },
      "COMMENTS": {
       S: form.comments
      },
      "CHECKIN_DATE": {
       N: form.checkinDate.getTime().toString()
      },
      "CHECKIN_DATE_STRING": {
       S: form.checkinDate.toString()
      },
    }, 
    TableName: "UploadEntries"
  };
}

const getSketchEntryParams = (form) => {
  return {
    Item: {
     "NAME": {
       S: form.name
      }, 
     "AGENCY": {
       S: form.agency
      }, 
     "EMAIL": {
       S: form.email
      },
      "PHONE": {
       S: form.phone
      },
      "SUBMISSION_TYPE": {
       S: form.area
      },
      "PROJECT_STATUS": {
       S: form.status
      },
      "SUBMISSION": {
       S: form.submission.toString()
      },
      "ID" : {
        S: form.email+ "_" + form.submission.getTime().toString()
      },
      "PROJECT_NAME": {
       S: form.projectName
      },
      "PROJECT_START": {
        S: form.projectStart.toString()
      },
      "PROJECT_END": {
       S: form.projectEnd.toString()
      },
      "PROJECT_DESCRIPTION": {
       S: form.projectDescription
      },
      "TARGET_SPECIES": {
       S: form.targetSpecies
      },
      "COUNT": {
       S: form.count
      },
      "ENV_CLEARANCE": {
       S: form.environmentalClearance
      },
      "COMMENTS": {
       S: form.comments
      },
      "CHECKIN_DATE": {
       N: form.checkinDate.getTime().toString()
      },
      "CHECKIN_DATE_STRING": {
       S: form.checkinDate.toString()
      },
      "GIS_OBJECTID": {
       S: form.objectId.toString()
      },
    }, 
    TableName: "UploadEntries"
  };
}

export const queryTable = ()=> async(dispatch) =>{
  var docClient = new AWS.DynamoDB.DocumentClient({apiVersion: '2012-08-10'});
  var date = new Date()
  var params = {
    ExpressionAttributeValues: {
        ':s': date.toString(),
        ':status': 'Planned',
       },
    KeyConditionExpression: 'CHECKIN_DATE <= :s',
    FilterExpression: 'contains (PROJECT_STATUS, :status)',
    TableName: 'UploadEntries'
  }

  docClient.query(params, function(err, data) {
    if (err) {
      console.log("Error", err);
    } else {
      console.log("Success", data.Items);
    }
  });
}