import React, {Component} from 'react'
import {connect} from 'react-redux'
import Header from '../../presentational/Header/Header'
import {closeModal, closeDrawer} from '../../../actions/uiActions'
import {queryMapClickLayer} from '../../../actions/esri/esriMapActions'
// import {PropTypes} from 'prop-types'


const LOGO = require('./logo.png')

class HeaderContainer extends Component {
  static propTypes = {}

  static defaultProps = {}

  onLayerItemSelection(selection){
    this.props.dispatch(closeModal('selectionModal'))
    this.props.dispatch(queryMapClickLayer(selection.layer, selection.mapPoint, selection.resolution ))
  } 

  onActionItemSelection(){
    this.props.dispatch(closeDrawer())
  }


  render() {
    return(
      <Header onLayerItemSelection={this.onLayerItemSelection.bind(this)} onActionItemSelection={this.onActionItemSelection.bind(this)} logo={LOGO}/>
    )
  }
}

HeaderContainer = connect()(HeaderContainer)
export default HeaderContainer