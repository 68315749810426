import {handleActions} from "redux-actions"
import Immutable from "immutable"

let defaultState = Immutable.fromJS({
  activeModal: null,
  modalProps: {},
  activeDrawer: null,
  drawerProps: {},
  windowWidth: 767,
  submitProps: {}
})

export default handleActions({
  OPEN_MODAL: (state, action) => {
    let {id, props} = action.payload
    return state.withMutations(s => s.set('activeModal', id).set('modalProps', props))
  },
  UPDATE_WINDOW_WIDTH: (state, action) => {
    let width = action.payload.width
    return state.withMutations(s => s.set('windowWidth', width))
  },

  UPDATE_MODAL_PROPS: (state, action) => {
    let {id, props} = action.payload
    return state.withMutations(s => s.set('activeModal', id).set('modalProps', props))
  },

  UPDATE_SUBMISSION_PROPS: (state, action) => {
    // let {id, props} = action.payload
    let {props} = action.payload
    return state.withMutations(s => s.set('submitProps', props))
  },

  CLOSE_MODAL: (state, action) => {
    return state.withMutations(s => s.set('activeModal', null).set('modalProps', {}))
  },

  OPEN_DRAWER: (state, action) => {
    let {id, props} = action.payload

    return state.withMutations(s => s.set('activeDrawer', id).set('drawerProps', props))
  },

  CLOSE_DRAWER: (state, action) => {
    return state.withMutations(s => s.set('activeDrawer', null))
  }
}, defaultState)
