import React, {Component} from 'react'
import {PropTypes} from 'prop-types'

require('./AttributeList.scss')

class AttributeList extends Component {


  static propTypes = {
    body: PropTypes.array
  }

  static defaultProps = {
    body: []
  }

  renderBody() {
    const {body} = this.props

    return body.map(f => {
      return <div className="AttributeList__item" key={f.attribute}><span className="AttributeList__attribute">{`${f.nickName}`}:</span> {`${f.value}`}</div>
    })
  }

  render() {
    return(
      <div className="AttributeList">
        {this.props.body.length && this.renderBody()}
      </div>
    )
  }
}

export default AttributeList
