// import {uploadError} from '../uploadActions'
import {updateSubmissionModal} from '../uiActions'
import AWS from 'aws-sdk'

export const buildS3FileParams = (file, id) => {
   var objKey = id + '/' + file.name;
   let buf = {
        Key: objKey,
        ContentType: file.type,
        Body: file,
        ACL: 'private',
        Bucket: 'upload-tool'
    }
    return buf
}

export const uploadAllFiles = (files, id, dispatch) =>{
  let promises = files.map(file => {
    return uploadFileToS3(buildS3FileParams(file, id), file.name, dispatch)
  })
  return Promise.all(promises)
}

export const uploadFileToS3 = (params, name, dispatch)  =>  {
    var bucket = new AWS.S3({apiVersion: '2006-03-01'})
    return bucket.upload(params)
   .on('httpUploadProgress', function(evt) {
       let progress = parseInt((evt.loaded / evt.total) *100)
       dispatch(updateSubmissionModal(progress, "Uploading " + name + " - " + progress + "%", dispatch))
    })
    .promise().then(function (data) {
        return data
    }).catch(function(err){
    })
}


// const concludeMultiPartUpload = () => {

// }

// const abortMultipartUpload = () => {

// }

