// import AWS from 'aws-sdk'
import {updateActiveGrowls} from './growlActions'
import {hasCredentials, getCredentials} from './aws/credentials'
// import {buildS3FileParams, uploadFileToS3, uploadAllFiles} from './aws/s3'
import {uploadAllFiles} from './aws/s3'
import {putUploadEntry} from './aws/dynamodb'
import {buildUserEmail, sendEmail, buildAdminEmail, buildUserSketchEmail, buildAdminSketchEmail} from './aws/ses'
import {updateSubmissionModal} from './uiActions'
import {handleSketchFormSubmission} from './esri/sketchActions'
import {changeFormError, changeSubmitComplete} from './formActions'

export const uploadFile = (formState) => async (dispatch) => {
  dispatch(updateSubmissionModal(0, "Uploading File(s)"))
  if (formState.files != null && formState.files.length > 0) {
    if(await hasCredentials() === null){ 
        // let results = await uploadFileToS3(buildS3FileParams(formState.fileActual, formState.email + "_" + formState.submission.toString()), dispatch)
        // let results = await uploadAllFiles(formState.files, formState.email + "_" + formState.submission.getTime().toString(), dispatch)
        uploadAllFiles(formState.files, formState.email + "_" + formState.submission.getTime().toString(), dispatch).then( (results) => {
          let updateForm = Object.assign({}, formState)
          updateForm.url = "https://s3.console.aws.amazon.com/s3/buckets/upload-tool/" + formState.email + "_" + formState.submission.getTime().toString() + "/"
          dispatch(uploadUserEntry(updateForm))
        }).catch( (error) => {
          dispatch(updateSubmissionModal(0, "Error uploading your file: " + error.toString()))
          // dispatch(changeFormError(true))
        } )
    }
    else{
        dispatch(updateSubmissionModal(0, "Error obtaining credentials while uploading your file"))
    }
  }
  else{
    dispatch(updateSubmissionModal(0, "Error reading your file"))
    dispatch(changeFormError(true))
  } 
}

export const uploadUserEntry = (form) => async(dispatch) => {
  dispatch(updateSubmissionModal(100, "Saving to the database"))
  if(await hasCredentials() === null) {
    // let results = await putUploadEntry(form)
    putUploadEntry(form).then( results =>{
      dispatch(emailUser(form))
    }).catch(error => {
            dispatch(changeFormError(true))
            dispatch(updateSubmissionModal(100, "Error saving to the database: " + error.toString()))
    })
  }
  else{ 
    dispatch(updateSubmissionModal(100, "Error obtaining credentials while saving to the database"))
    dispatch(changeFormError(true))
  }
}

const emailUser = (form) => async(dispatch) => {
  dispatch(updateSubmissionModal(0, "Sending emails"))
  if(await hasCredentials() === null){
     var emailForm = buildUserEmail(form)
     if(form.area === "sketch"){
      emailForm = buildUserSketchEmail(form)
     } 
    sendEmail(emailForm, dispatch, "sending your confirmation email").then( results => {
      dispatch(emailAdmin(form))
}).catch(error => {
      dispatch(updateSubmissionModal(50, "Error sending your confirmation email: " + error.toString()))
      dispatch(changeFormError(true))
    })
  }
  else{
      dispatch(updateSubmissionModal(50, "Error obtaining credentials while sending your confirmation email"))
      dispatch(changeFormError(true))
  }

}

const emailAdmin = (form) => async(dispatch) => {
  dispatch(updateSubmissionModal(50, "Sending emails"))
  if(await hasCredentials() === null){
    var emailForm = buildAdminEmail(form)
    if(form.area === "sketch"){
      emailForm = buildAdminSketchEmail(form)
    }
    sendEmail(emailForm, dispatch, "shipping your submission to the team").then(results => {
      dispatch(updateSubmissionModal(100, "Sending emails"))
      dispatch(updateSubmissionModal(100, "We've sent you a confirmation email."))
      dispatch(changeSubmitComplete(true))
    }).catch(error => {
        console.log("admin email rror")
        console.log(error)
        dispatch(updateSubmissionModal(100, "Error sending admin confirmation email: " + error.toString()))
        dispatch(changeFormError(true))
    })
  }
  else{
      dispatch(updateSubmissionModal(100, "Error obtaining credentials while shipping to admin"))
      dispatch(changeFormError(true))
  }

}

export const uploadError = (message) =>  async (dispatch) =>{
   dispatch(updateActiveGrowls({
      title: `${message}`,
      message: 'Please contact us for assistance',
      autoDismiss: 8, 
      level: 'error',
      position: 'tc'
    }))
}

export const removeBlankValues = (form) => {
  let newForm = Object.assign({}, form)
  for (let property in newForm) {
    if ((typeof newForm.property === "object" && Object.keys(newForm.property).length === 0) || (newForm.hasOwnProperty(property) && newForm[property] !== null && newForm[property].length === 0)) {
        // do stuff
        newForm[property] = "no response"
    }
  }
  return newForm
}

const getCheckinDate = (formState)  => {
 
    if(formState.status !== "completed" && formState.count !== "many"){
      let checkinDate = new Date(formState.projectEnd.toString())
      checkinDate.setMonth(checkinDate.getMonth() - 6)
      return checkinDate
    }
    else{
      return new Date(-5707691837000)
    }
  }

export const handleFormSubmission = (formState) =>  async(dispatch) => {
  let c = getCredentials()
  formState.submission = new Date()
  formState.checkinDate = getCheckinDate(formState)

    let {name, agency, email, phone, count, status, projectName, projectStart, projectEnd, projectDescription, targetSpecies, environmentalClearance, comments, description, files, graphic, url, checkinDate,  area, submission} = formState
    let newForm = {name, agency, email, phone, count, status, projectName, projectStart, projectEnd, projectDescription, targetSpecies, environmentalClearance, comments, description, files, graphic, url, checkinDate,  area, submission}

  // //sketch or upload?
  if(formState.area === "upload"){
    let newF = removeBlankValues(newForm)
    dispatch(uploadFile(removeBlankValues(newF)))
  }
  else if(formState.area === "sketch"){
    dispatch(handleSketchFormSubmission(newForm))
  }




}