import "isomorphic-fetch"
// import SketchViewModel from 'esri/widgets/Sketch/SketchViewModel'
// import {loadGraphic, loadFeatureLayer, loadSketchViewModel} from '../../components/utils/esriLoader'
import {loadGraphic, loadFeatureLayer} from '../../components/utils/esriLoader'
import {updateSubmissionModal} from '../uiActions'
// import {putSketchEntry} from '../aws/dynamodb'
// import {hasCredentials, getCredentials} from '../aws/credentials'
import {removeBlankValues, uploadUserEntry} from '../uploadActions'
// import {updateActiveGrowls} from '../growlActions'
// import URLSearchParams from 'url-search-params-polyfill';
// import {changeFormError, changeSubmitComplete} from '../formActions'
import {changeFormError} from '../formActions'
// import FeatureLayer from 'esri/layers/FeatureLayer'


export const setSketchGraphic = (graphic) => (dispatch, getState) => {
     dispatch({
        type: "SET_GRAPHIC",
        payload: graphic
      })
}

export const initSketchViewModel = (sketch) => (dispatch, getState) => {
    dispatch({
      type: "INITIATE_SKETCH",
      payload: sketch
    })

}


export const resetSketch = () => (dispatch, getState) => {
  dispatch(setSketching(true))
  dispatch(setSketchGraphic({}))
}

export const setSketching = (sketching) => (dispatch, getState) => {
  dispatch({
    type: "SET_SKETCHING",
    payload: sketching
  })
}

export const stopSketching = () =>  async(dispatch)  => {
  dispatch(setSketching(false))
  dispatch({
    type: "SET_GRAPHIC",
    payload: {}
  })
}


export const handleSketchFormSubmission = (formState) =>  async(dispatch) => {
  let formWithoutBlanks = removeBlankValues(formState)

  let newGraphic = formState.graphic

  dispatch(updateSubmissionModal(0, "Saving your entry in the GIS database"))

  
   loadFeatureLayer('https://services1.arcgis.com/Pat8LQYI0Udhgy4G/arcgis/rest/services/UploadToolSketchData/FeatureServer/0').then((featureLayer) => {
      let g = featureLayer.applyEdits({
        addFeatures: [newGraphic]
      })
      g.then(response => {
          formWithoutBlanks.objectId = response.addFeatureResults[0].objectId
          dispatch(uploadUserEntry(formWithoutBlanks))
      }).catch(error => {
        dispatch(updateSubmissionModal(0, "Error saving to the GIS database: " + error.toString()))
        dispatch(changeFormError(true))
      })
   }) 


}