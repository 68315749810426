import React, {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import SvgIcon from 'material-ui/SvgIcon';
import ListContainer from '../../../../container/ListContainer/ListContainer'
import Group from '../../../Group/Group'
import HelpItem from '../../../HelpItem/HelpItem'
import {isEmpty} from '../../../../utils/helpers.js'

require('./YourArea.scss')


class YourArea extends Component {

  constructor(props) {
    super(props)
    this.state = {
    }

 }

 getStartSketchingBtnClassName() {
    return classNames("YourArea__sketch-button", {
      "YourArea__sketch-button-active" : (this.props.sketching && isEmpty(this.props.graphic)),
      "YourArea__sketch-button-error" : (this.props.isEmpty(this.props.graphic) && !this.props.sketching)
    })
 }      

 getSketchingBtnText(){

  if(isEmpty(this.props.graphic)){
    if(this.props.sketching){
      return "stop sketching"
    }
    else{
     return "start sketching*"
    }
  }
  else if(!this.props.sketching){
    return "sketch complete"
  }

 }

 getTrashCanStyle(){

   if(!isEmpty(this.props.graphic)){
    return {
        height: 40,
        width: 40,
        cursor: 'pointer'
    }
  }
  else{
    return {
        height: 40,
        width: 40,
        opacity: 0.4,
        cursor: 'default'
    }
  }   

 }

  getCheckMarkStyle(){

    return {
        height: 20,
        verticalAlign: 'bottom',
        width: 20,
        cursor: 'default',
        color: "#FFFFFF",
        paddingRight: '2px'
    }
  

 }

 getTrashCanClass(){
  if(isEmpty(this.props.graphic)){
    return classNames("YourArea__clickable")
  }
  else{
    return classNames("YourArea__not-clickable")
  }
 }

  getSketchTextClass(){
    return classNames("YourArea__sketch-description", {"YourArea__sketch-description-sketching" : (this.props.sketching && this.props.isEmpty(this.props.graphic))
  })
  
  }

  getCheckMark(){
    if(!isEmpty(this.props.graphic)){
      return (
            <SvgIcon style={this.getCheckMarkStyle()} >
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
            </SvgIcon>
      )
    }
  }

 
  gettingStartedText(){
    return (
       <div>
       <p>Zoom to your treatment area and use the buttom above to start sketching its boundary.</p>
       <p>After your treatment area is drawn on the map, you will be able to fill out the treatment details below.</p>
       </div> 
    )
  }

  getDrawingText(){
      return (
         <div>
         <p>Click on the map to start your sketch. Hold the mouse button down and drag your pointer around your treatment area. Alternatively, you can click once for each vertex.</p>
         <p>To finish your sketch, double click on the map, or press the C button on your keyboard.  Use the trash can above if you want to start over.</p>
         </div> 
      ) 
  }
   
  render(){
    
    return (

      <div className="SketchForm__group">
        <span className="TabContainer__toggle-description">Zoom to your area before you start sketching and double click to finish your sketch. Get more help with sketching below. </span>
       <div className="SketchForm__toggle">
        <div className={this.getStartSketchingBtnClassName()} onClick={this.props.handleSketchButton.bind(this)}>{this.getCheckMark()}{this.getSketchingBtnText()}</div>
        <MuiThemeProvider>
          <SvgIcon className={this.getTrashCanClass()} onClick={this.props.resetSketch.bind(this)} color={"#d9d6d7"} style={this.getTrashCanStyle()} >
             <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </SvgIcon>
        </MuiThemeProvider>
        </div>
        <div className="TabContainer__toggle-error">{this.props.getToggleErrorText(this.props.sketchError, this.props.tabError)}</div>  
        <ListContainer />
        <Group id={2} name="Get Help With Sketching" >
        <HelpItem id={1} title="Getting Started" helpText={this.gettingStartedText()}/>
        <HelpItem id={3} title="Sketching Your Area" helpText={this.getDrawingText()}/>
        </Group>
      </div>

      )
    }

}

YourArea = connect((state) => ({
}))(YourArea)
export default YourArea
