export function queryFeatureLayer (layer, extent) { 
  
     return layer.queryFeatures({ 
              geometry: extent, 
              returnGeometry: true, 
              outFields: layer.outFields,
            
            }).then(results => { 
               if(results.features){  
                return results.features
               }
               else{
                return []
               }
    })
     
    // return []        
}