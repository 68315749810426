import "isomorphic-fetch"
import {openDrawer} from '../../actions/uiActions'
// import all from 'dojo/promise/all'
import {init, noResults} from '../../actions/esri/vtRepoClickActions/mapClickActions'
import {openModal} from '../../actions/uiActions'
import React from 'react'
import AttributeList  from '../../components/presentational/_drawers/DetailDrawer/AttributeList'
import changeCase from 'change-case'

// for example purposes
// import MockDetails from 'presentational/_drawers/DetailDrawer/MockDetails'

export const itemToggleDisabled = (id, disabled) => (dispatch, getState) => {
  dispatch({
    type: "ITEM_TOGGLE_DISABLED",
    payload: {
      id,
      disabled
    }
  })
}

export const toggleItem = (itemId) => (dispatch, getState) => {
  dispatch({
    type: "MAP_ITEM_TOGGLE",
    payload: itemId
  })
}

export const toggleGroup = (groupId) => (dispatch, getState) => {
  dispatch({
    type: "MAP_GROUP_TOGGLE",
    payload: groupId
  })
}

export const getItemDetails = (itemId) => (dispatch, getState) => {
  dispatch({
    type: "MAP_ITEM_GET_DETAILS",
    payload: itemId
  })
}

export const handleMapClick = (allLayerViews, mapPoint, resolution) => (dispatch, getState) => {
   let queryableLayers = getQueryableLayers(allLayerViews).reverse()
   if (queryableLayers.length){
    if(queryableLayers.length === 1){
      dispatch(init(queryableLayers[0], mapPoint, resolution))
    }
    else{
      dispatch(openModal('selectionModal', {queryableLayers, mapPoint, resolution})) 
    }
   }
   else{
    dispatch(noResults('No queryable layers are visible'))
   } 
}

//called when user selects a layer from the selectionModal
export const queryMapClickLayer = (layerView, mapPoint, resolution) => async (dispatch, getState) => {
  dispatch(init(layerView, mapPoint, resolution))

}

export const mapUpdating = (updating) => (dispatch, getState) => {
  dispatch({
    type: "MAP_UPDATING",
    payload: updating
  })
}

const getQueryableLayers = (allLayerViews) => {
    let queryableLayers = []
    allLayerViews.forEach(layerView => {
      if(layerView.visible && layerView.layer.type === "feature"){
        queryableLayers.push(layerView)
      }
    
    })
    return queryableLayers
}

export const requestOpenDrawer = (bodyDetails, name) =>(dispatch, getState) =>{

  dispatch(openDrawer("detailDrawer", {
    header: name,
    body: <AttributeList body={bodyDetails}/>
  }))

}

export const generateDrawerBodyFromAttributes = (queryFields, attributes) => {

  let body = queryFields.map((f) => {
    return {attribute: f.fieldName, value: modifyDisplay(attributes[f.fieldName], f.textType), nickName: f.nickName}
  })
 
  return body
}

const modifyDisplay = (value, dType) => {
 
  const displayType = {
    'string': value,
    'date': convertDate(value),
    'number': Number(value).toLocaleString(),
    'title' : changeCase.title(value),
    'lower' : changeCase.lower(value),
    'agency' : getAgency(value),
    'name': getName(value)

  }

  return displayType[dType]
}

const getName = (value) => {
  if(value === "needs input"){
    return 'Project Unnamed'
  }
  return value
}


const getAgency = (value) => {

  const agencyType = {
    '2': 'USGS',
    '3': 'BLM',
    '4' : 'SLO',
    '5': 'Tribal',
    '6': 'BIA',
    '1': 'NMSF',
    '7': 'needs input'
  }
  return agencyType[value]
}
const convertDate = (milliseconds) => {
  if (milliseconds) {
    let d = new Date(milliseconds)

    let minutes = d.getUTCMinutes()
    if(minutes < 10){
      minutes = "0" + minutes.toString()
    }
    return d.getUTCHours() + ":" + minutes + " " + (d.getUTCMonth() + 1) + "/" + d.getUTCDate() + "/" + d.getUTCFullYear()
  }
  return ""
}

export const updateSelection = (layerId, featureId, uniqueField, geometry = {}) => (dispatch, getState) => {
    let payload ={}
    payload.selection = {
      layerId: layerId,
      featureId: featureId,
      uniqueField: uniqueField,
      geometry: geometry
    }

  dispatch({
      type: "SELECTION_UPDATE",
      payload: payload
  })
  dispatch(mapUpdating(false))
}
