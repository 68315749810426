import React, {Component} from 'react'
import classNames from 'classnames'
import i from './iconsMap'
import {keys, noop} from 'lodash'
import {PropTypes} from 'prop-types'

require('./Icon.scss')

class Icon extends Component {
  static propTypes = {
    name: PropTypes.oneOf(keys(i)).isRequired,
    onClick: PropTypes.func,
    size: PropTypes.number
  }

  static defaultProps = {
    onClick: noop,
    size: 20
  }

  getClassNames() {
    return classNames('Icon', this.props.className, {
      'Icon--loader': this.props.name === 'loader'
    })
  }

  render() {
    return(
      <svg className={this.getClassNames()}
        onClick={this.props.onClick}
        style={{width: `${this.props.size}px`, height: `${this.props.size}px`}}
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid meet" 
        aria-labelledby="title">
        <title id="title">{i[this.props.name].title}</title>
        <g><path d={i[this.props.name].path}></path></g>
      </svg>
    )
  }
}

export default Icon
