import AWS from 'aws-sdk'
import {updateSubmissionModal} from '../uiActions'

export const buildUserEmail =(form) => {
  let {email} = form
  return {
    Destination: {
     ToAddresses: [
        form.email 
     ]
    }, 
    Message: {
     Body: {
      Html: {
       Charset: "UTF-8", 
       Data: "Hi " + form.name + "-" + "<br><br>Thanks for sharing your vegetation treatments using the NM Vegetation Treatments Upload Tool.<br><br> Your submission ID is: " + form.email + "_" + form.submission.getTime().toString() +  getUserCheckinDate(form) + "<br><br>We will reach out to you with any questions we may have regarding your submission.<br><br>Don't hesitate to contact us with questions or concerns." +  "<br><br>New Mexico Forest and Watershed Restoration Institute<br>New Mexico Highlands University | Box 9000 Las Vegas NM 87701<br>505.454.3586 | kwithnall@nmhu.edu | www.nmfwri.org<br><a class=\"ulink\" href=\"http://arcg.is/2oCJgjI\" target=\"_blank\">NM Vegetation Treatments</a>"
      }, 
      Text: {
       Charset: "UTF-8", 
       Data: "Hi " + form.name + "-" + "<br><br>Thanks for sharing your vegetation treatments using to the NM Vegetation Treatments Upload Tool.<br><br>Your submission ID is: " + form.email + "_" + form.submission.getTime().toString() + getUserCheckinDate(form) + "<br><br>We will reach out to you with any questions we may have regarding your submission.<br><br>Don't hesitate to contact us with questions or concerns." + "<br><br>New Mexico Forest and Watershed Restoration Institute<br>New Mexico Highlands University | Box 9000 Las Vegas NM 87701<br>505.454.3586 | kwithnall@nmhu.edu | www.nmfwri.org<br><a class=\"ulink\" href=\"http://arcg.is/2oCJgjI\" target=\"_blank\">NM Vegetation Treatments</a>"
      }
     }, 
     Subject: {
      Charset: "UTF-8", 
      Data: "Thanks for your submission!"
     }
    }, 
    ReplyToAddresses: ["hello@vegetationtreatments.org"],  
    Source: "hello@vegetationtreatments.org", 
  }
}

export const buildUserSketchEmail =(form) => {
  let {email} = form
  return {
    Destination: {
     ToAddresses: [
        form.email 
     ]
    }, 
    Message: {
     Body: {
      Html: {
       Charset: "UTF-8", 
       Data: "Hi " + form.name + "-" + "<br><br>Thanks for sketching and sharing your vegetation treatments using the NM Vegetation Treatments Upload Tool.<br><br>Your submission ID is: " + form.email + "_" + form.submission.getTime().toString() + getUserCheckinDate(form) + "<br><br>We will reach out to you in the meantime with any questions we may have regarding your submission.<br><br>Don't hesitate to contact us with questions or concerns.<br><br>New Mexico Forest and Watershed Restoration Institute<br>New Mexico Highlands University | Box 9000 Las Vegas NM 87701<br>505.454.3586 | kwithnall@nmhu.edu | www.nmfwri.org<br><a class=\"ulink\" href=\"http://arcg.is/2oCJgjI\" target=\"_blank\">NM Vegetation Treatments</a>"
      }, 
      Text: {
       Charset: "UTF-8", 
       Data: "Hi " + form.name + "-" + "<br><br>Thanks for sketching and sharing your vegetation treatments using to the NM Vegetation Treatments Upload Tool.<br><br>Your submission ID is: " + form.email + "_" + form.submission.getTime().toString() + getUserCheckinDate(form) + "<br><br>We will reach out to you in the meantime with any questions we may have regarding your submission.<br><br>Don't hesitate to contact us with questions or concerns.<br><br>New Mexico Forest and Watershed Restoration Institute<br>New Mexico Highlands University | Box 9000 Las Vegas NM 87701<br>505.454.3586 | kwithnall@nmhu.edu | www.nmfwri.org<br><a class=\"ulink\" href=\"http://arcg.is/2oCJgjI\" target=\"_blank\">NM Vegetation Treatments</a>"
      }
     }, 
     Subject: {
      Charset: "UTF-8", 
      Data: "Thanks for your sketch submission!"
     }
    }, 
    ReplyToAddresses: ["hello@vegetationtreatments.org"],  
    Source: "hello@vegetationtreatments.org", 
  }
}

export const buildAdminEmail =(form) => {
  return {
    Destination: {
     ToAddresses: [ 
        "hello@vegetationtreatments.org",
     ],
    }, 
    Message: {
     Body: {
      Html: {
       Charset: "UTF-8", 
       Data: "A new file upload treatment has been submitted using the Upload Tool:<br><br>Name: " + form.name + "<br>Agency: " + form.agency + "<br>Email: " + form.email + "<br>Phone: " + form.phone + "<br>Projects submitted: " + form.count + "<br>Status: " + form.status +  getProjectDetails(form) + "<br>Data Description: " + form.description + "<br>File Upload Url: " + form.url + "<br>Submission ID: " + form.email + "_" + form.submission.getTime().toString() + getCheckinDate(form)
      }, 
      Text: {
       Charset: "UTF-8", 
        Data: "A new file upload treatment has been submitted using the Upload Tool:<br><br>Name: " + form.name + "<br>Agency: " + form.agency + "<br>Email: " + form.email + "<br>Phone: " + form.phone + "<br>Projects submitted: " + form.count + "<br>Status: " + form.status  + getProjectDetails(form) + "<br>Data Description: " + form.description + "<br>File Upload Url: " + form.url + "<br>Submission ID: " + form.email + "_" + form.submission.getTime().toString() + getCheckinDate(form)
      }
     }, 
     Subject: {
      Charset: "UTF-8", 
      Data: "Upload Tool File Submission"
     }
    }, 
    ReplyToAddresses: ["hello@vegetationtreatments.org"],  
    Source: "hello@vegetationtreatments.org", 
  }
}

export const buildAdminSketchEmail =(form) => {
  return {
    Destination: {
     ToAddresses: [ 
        "hello@vegetationtreatments.org",
     ],
    }, 
    Message: {
     Body: {
      Html: {
       Charset: "UTF-8", 
       Data: "A new sketch treatment has been submitted using the Upload Tool:<br><br>Name: " + form.name + "<br>Agency: " + form.agency + "<br>Email: " + form.email + "<br>Phone: " + form.phone +  "<br>Status: " + form.status +  getSketchProjectDetails(form)  + "<br>GIS ObjectID: " + form.objectId + "<br>Submission ID: " + form.email + "_" + form.submission.getTime().toString() + getCheckinDate(form)
      }, 
      Text: {
       Charset: "UTF-8", 
        Data: "A new sketch treatment has been submitted using the Upload Tool:<br><br>Name: " + form.name + "<br>Agency: " + form.agency + "<br>Email: " + form.email + "<br>Phone: " + form.phone + "<br>Status: " + form.status  + getSketchProjectDetails(form)  + "<br>GIS ObjectID: " + form.objectId + "<br>Submission ID: " + form.email + "_" + form.submission.getTime().toString() + getCheckinDate(form)
      }
     }, 
     Subject: {
      Charset: "UTF-8", 
      Data: "Upload Tool Sketch Submission"
     }
    }, 
    ReplyToAddresses: ["hello@vegetationtreatments.org"],  
    Source: "hello@vegetationtreatments.org", 
  }
}


const getCheckinDate = (form) => {

  if(form.status !== "completed" && form.count !== "many"){
    let date = form.checkinDate
    return "<br>Reminder date: " + date.toString()
  } 
  else{
    return ""
  }
}

const getUserCheckinDate = (form) =>{

  if(form.status !== "completed"){

    let date = form.checkinDate
    return "<br><br>We will send you an email to followup with us around: " + date.toString() + " regarding your " + form.status + " project."
  } 
  else{
    return ""
  }
}

const getProjectDetails = (form)=>{
  if(form.count === "one"){
    return "<br>Project Name: " + form.projectName + "<br>Project Start Year: " + form.projectStart + "<br>Project End: " + form.projectEnd + "<br>Project Description: " + form.projectDescription + "<br>Target Species: " + form.targetSpecies + "<br>Environmental Clearance: " + form.environmentalClearance 
  }
  else{
    return ""
  }
}

const getSketchProjectDetails = (form)=>{
  return "<br>Project Name: " + form.projectName + "<br>Project Start Year: " + form.projectStart + "<br>Project End: " + form.projectEnd + "<br>Project Description: " + form.projectDescription + "<br>Target Species: " + form.targetSpecies + "<br>Environmental Clearance: " + form.environmentalClearance
}

export const sendEmail = (params, dispatch, action) => {
  let ses = new AWS.SES({apiVersion: '2010-12-01'});
  return new Promise(function(resolve, reject) {
    ses.sendEmail(params, function(err, data) {
      if(err){
        dispatch(updateSubmissionModal(100, "Error " + action))  
        reject(err)
      }
      else{
        resolve(data)
      }
    })
  })
}