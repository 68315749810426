import React from 'react'
// import classNames from 'classnames'
// import {PropTypes} from 'prop-types'

require('./Tab.scss')
const Tab = (props) => {
  // const {
  //   body
  // } = props

  return (
    <div className="Tab">
      {props.children}
    </div>
  )
}

export default Tab
