import React, {Component} from "react"
import {connect} from "react-redux"
import Modal from "../../Modal/Modal"
import {closeModal} from '../../../../actions/uiActions'
// import NavLink from '../../NavLink/NavLink'
// import {NavLink} from 'react-router-dom'
// import LinearProgress from 'material-ui/LinearProgress';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import CircularProgress from 'material-ui/CircularProgress';
import SvgIcon from 'material-ui/SvgIcon';
import {resetForm} from '../../../../actions/formActions'
// import {PropTypes} from 'prop-types'

require('./SubmissionModal.scss');

const id = "submissionModal"

class SubmissionModal extends Component {
  static propTypes = {
    
  }

  handleContributeClick() {
        this.props.dispatch(closeModal("SubmissionModal"))
  }

  getProgressValue(){
    return (this.props.progress) ? this.props.progress : 0
  }

  getDescriptionValue(){
    return (this.props.description) ? this.props.description : "Submitting form"
  }

  getProgressCircle(){
    if(this.props.description !== "We've sent you a confirmation email."){
      return <CircularProgress color="#efce06" mode="indeterminate"/>
    }
    else{
      let iconStyles = {
        height: 40,
        width: 40
      }
      return(
        <SvgIcon color={"#5abee9"} style={iconStyles} >
          <path d="M0 0h24v24H0z" fill="none"/>
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
        </SvgIcon>
      )
    }
  }

  getHeader(){
    if(this.props.description !== "We've sent you a confirmation email."){
      return "Processing Your Submission"
    }
    else{
      return "Thanks For Your Submission!"
    }
  }

  onRequestClose(){
      this.props.dispatch(resetForm(true))
  }

  
  render() {
    return (
      <div>
        <Modal onRequestClose={this.onRequestClose.bind(this)} className="SubmissionModal" id={id} ref="modal">
          <h2 className="Modal__header">{this.getHeader()}</h2>
          <div className="Modal__description">
            <div className="SubmissionModal__upload-category">
              <div className="SubmissionModal__upload-category-label">{this.getDescriptionValue()}</div>
              <MuiThemeProvider>
                {this.getProgressCircle()}

              </MuiThemeProvider>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

SubmissionModal = connect(state => {
  let props = state.ui.get('modalProps')
  return {...props}
})(SubmissionModal)

SubmissionModal.id = id
export default SubmissionModal
