// TODO: create a layer index to making importing more concise

import React, {Component} from 'react'
import {connect} from 'react-redux'
import {isEmpty} from 'lodash'
import classNames from 'classnames'
// import createEsriMap from './utils/createEsriMap'
// import MapControls from './layers/MapControls'
import FeatureServiceLayer from './layers/FeatureServiceLayer'
import {closeDrawer} from '../../../actions/uiActions'
import {handleMapClick, mapUpdating, itemToggleDisabled, updateSelection} from '../../../actions/esri/esriMapActions'
// import Graphic from "esri/Graphic"
// import SimpleFillSymbol from "esri/symbols/SimpleFillSymbol"
import {updateActiveGrowls} from '../../../actions/growlActions'
import {initSketchViewModel, setSketchGraphic, setSketching} from '../../../actions/esri/sketchActions'
// import Basemap from 'esri/Basemap'
import {resetForm} from '../../../actions/formActions'

import {  loadMapView, loadSketchViewModel, loadGraphicFromGeometry} from '../../utils/esriLoader'



// import {PropTypes} from 'prop-types'

require('./EsriMapp.scss')

class EsriMapp extends Component {

  constructor(props) {
    super(props)
    this.mapViewContainer = React.createRef();
    this.state = {
      view: null,
      esriMap: null,
      topo: null,
      aerial: null, 
      mapUpdating: null, 
      sketching: false,
      sketchViewModel : null,
      graphic: {}
    };
  }

  static propTypes = {}

  static defaultProps = {}

  componentDidUpdate(){
    if(this.state.sketching && isEmpty(this.state.graphic)){
      this.clearGraphicsLayer()
      this.state.sketchViewModel.create("polygon")
    }
  }

  componentDidMount() {


    loadMapView(this.mapViewContainer.current, 'topo-vector', -109.5, 34.4, 4326, "hybrid", "top-right").then((view) => {

          const esriMap = view.map

          this.initSketchViewModel(view)
        
          view.on("click", (e) => {

                if(!view.updating && !this.props.sketching){
                    this.closeDetailView()
 
                    this.props.dispatch(updateSelection(0, 0, null, {}))
                    var resolution = view.state.resolution;
                    this.props.dispatch(handleMapClick(view.allLayerViews.items, e.mapPoint, resolution))
        
                }  
          })

          view.watch("updating", (response) => {
               if(response && !view.interacting && this.state.sketching === false){
                this.props.dispatch(mapUpdating(true))
               }
               else{
                this.props.dispatch(mapUpdating(false))
               } 
          })

          view.watch("zoom", (response) => {
            if(view.zoom >= 15 && this.state.esriMap.basemap.id !== "hybrid"){
              this.switchBaseMap()
            }
            else if(view.zoom < 15 && this.state.esriMap.basemap.id === "hybrid"){
              this.switchBaseMap()
            }
          })


          this.setState({view, esriMap})
        })

    }

    initSketchViewModel(view) {

       let polygonSymbol = {
            type: "simple-fill",
            color: "rgba(129,211,246, 0.4)",
            style: "solid",
            outline: {
              color: "rgba(237,23,78, 0.9)",
              width: 2
            }
          }

        view.map.layers.items.find((layer) => {
          if(layer.type === "graphics"){

                loadSketchViewModel(view, polygonSymbol, layer).then((sketch) => {

                sketch.on("create", (evt) => {

                    if(evt.state === "complete"){

                      loadGraphicFromGeometry(evt.graphic.geometry).then((graphic) => {
                        this.sketchComplete(graphic)
                      })

                    }
                    
                  });

                 this.props.dispatch(initSketchViewModel(sketch)) 

                 this.setState({sketchViewModel: sketch})

                })

              }
            })

    }

    sketchComplete(graphic) {
      this.props.dispatch(setSketching(false))
      this.props.dispatch(setSketchGraphic(graphic))
      this.props.dispatch(updateActiveGrowls({
        title: "Sketch Complete!",
        message: `If you are not happy with it, use the trash can to start your sketch over.`,
        autoDismiss: 5, 
        level: 'info',
        position: 'tc'
      }))

    }


  // loadBasemap() {

  //   loadBasemaps().then((baseMaps) => {
  //     const topo = baseMaps.topo
  //     const aerial = baseMaps.aerial

  //    this.setState({topo, aerial})
  //   })

  // }


  switchBaseMap(){
    let newView = Object.assign(this.state.view)

    newView.ui.components.forEach(item => {
        if(item.widget && item.widget.activeBasemap){
          item.widget.toggle()
        }
    })

    this.setState({view: newView})
  }

clearGraphicsLayer(){
  this.state.view.map.layers.items.find((layer) => {
    if(layer.type === "graphics"){
      layer.removeAll()
    }
  })

}

  static getDerivedStateFromProps(props, state){
    if(props.resetForm){
      if(state.esriMap.basemap.id === "hybrid"){
        this.switchBaseMap(props)
        props.dispatch(resetForm(false))
      }
    }

    return({sketching: props.sketching, graphic: props.graphic})
  }


  closeDetailView(){
    this.props.dispatch(closeDrawer())
  }

  getClassNames() {
    return classNames("EsriMapp", this.props.className) 
  }

  handleLayerLoadError(id){
    this.props.dispatch(itemToggleDisabled(id, true))
    this.props.dispatch(updateActiveGrowls({
      title: `Unable to load layer ${id}`,
      message: 'Reload the page or try again later',
      autoDismiss: 5, 
      level: 'error',
      position: 'tr'
    }))
  }

  handleAddLayer(layer, visible){

    if(this.state.esriMap.layers.includes(layer)){
    }
    else{
          if(visible) {
            this.state.esriMap.add(layer)
          }
          else{
            this.state.esriMap.remove(layer)
          }

    }

  }

  getLayer(item) {
    let { esriMap } = this.state
    let {
      id,
      options,
      options: {lType }
    } = item

    const layers = {
       'feature': <FeatureServiceLayer key={id} esriMap={esriMap} options={options} handleAddLayer={this.handleAddLayer.bind(this)} handleLayerLoadError={this.handleLayerLoadError.bind(this)} />,
      
    }

    return layers[lType]
  }

  renderLayers() {
    let _items = []
    this.props.items.map((item) => {
      return _items.push(this.getLayer(item.toJS()))
    })

    return _items
  }

  render() {

    return (
      <div ref={this.mapViewContainer} className={this.getClassNames()}>
        {!isEmpty(this.props.items) && !isEmpty(this.state.esriMap) && this.renderLayers()}
      </div>
    )
  }
}

EsriMapp = connect(state => ({
  items: state.esriMap.get('entities').getIn(['items']),
  selection: state.esriMap.get('selection'),
  sketching: state.sketch.sketching,
  graphic: state.sketch.graphic,
  resetForm: state.form.resetForm
}))(EsriMapp)
export default EsriMapp
