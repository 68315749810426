import React, {Component} from 'react'
import {connect} from 'react-redux'
import GettingStartedTab from '../../presentational/_tabs/GettingStartedTab/GettingStartedTab'
import YouTab from '../../presentational/_tabs/YouTab/YouTab'
import ProjectTab from '../../presentational/_tabs/ProjectTab/ProjectTab'
import AreaTab from '../../presentational/_tabs/AreaTab/AreaTab'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {
  Step,
  Stepper,
  StepLabel
} from 'material-ui/Stepper';
// import RaisedButton from 'material-ui/RaisedButton';
// import FlatButton from 'material-ui/FlatButton';
// import FinishTab from '../../presentational/_tabs/FinishTab/FinishTab'
// import {resetSketch} from '../../../actions/esri/sketchActions'
// import {openModal} from '../../../actions/uiActions'
import {handleFormSubmission} from '../../../actions/uploadActions'
// import {resetForm, changeFormError} from '../../../actions/formActions'
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import SubmissionTab from '../../presentational/_tabs/SubmissionTab/SubmissionTab'
// import {PropTypes} from 'prop-types'


require('./TabContainer.scss')

class TabContainer extends Component {
  static propTypes = {}

  static defaultProps = {}

  constructor(props) {
    super(props)
    this.state = {
      youTabError: false,
      projectTabError: false,
      areaTabError: false,
      // finished: false,
      stepIndex: 0,
      name: "",
      agency: "",
      email: "",
      phone: "",
      description: "",
      status: "",
      count: "",
      projectName: "",
      projectStart: {},
      projectEnd: {},
      projectDescription: "",
      targetSpecies: "",
      environmentalClearance: "",
      comments: "" ,
      submission: "",
      // fileActual: null,
      // fileName: "",
      files: [],
      graphic: {},
      sketching: false,
      url: "",
      checkinDate: "",
      area: "",
      mdScreen: 992,
      mdAside: false,
 
    }

   }

   static getDerivedStateFromProps(props, state){
    return({graphic: props.graphic, sketching: props.sketching})
   }


  componentDidMount(){
    this.handleResize()
    window.addEventListener('resize', this.handleResize.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this))
  }

  handleResize(e) {
    this.setState({mdAside: (window.innerWidth < this.state.mdScreen )})
  }

  getErrorStyle(value, tabError = true){
    return (value && tabError)? {color: '#5abee9'} : {}
  }

  getErrorText(value, tabError = true){
    return (value && tabError) ? "This field is required": ""  
  }

  getToggleErrorText(value, tabError = true){
    return (value && tabError) ? "This field is required" : ""
  }

  handleNext = (props) => {
    ///to do: handle case where all tabs are complete:
    // this.props.dispatch(openModal("submissionModal", {}))
    // this.props.dispatch(handleFormSubmission(this.state))

    this.setState({...props})
    const {stepIndex} = this.state;
    this.setState({
      stepIndex: stepIndex + 1,
      finished: stepIndex >= 4,
    });
  };

  switchTab(){
    const {stepIndex} = this.state;
    this.setState({
      stepIndex: stepIndex + 1,
      finished: stepIndex >= 4,
    });
  }

  handlePrev = (props) => {
        this.setState({...props})
    const {stepIndex} = this.state;
    if (stepIndex > 0) {
      this.setState({stepIndex: stepIndex - 1});
    }
  };

  handleSubmission(){

     this.props.dispatch(handleFormSubmission(this.state))

  }


  setInitialState = () => {
     const initialState = {
      youTabError: false,
      projectTabError: false,
      areaTabError: false,
      finished: false,
      stepIndex: 0,
      name: "",
      agency: "",
      email: "",
      phone: "",
      description: "",
      status: "",
      count: "",
      projectName: "",
      projectStart: {},
      projectEnd: {},
      projectDescription: "",
      targetSpecies: "",
      environmentalClearance: "",
      comments: "" ,
      submission: "",
      files: [],
     
      graphic: {},
      sketching: false,
      url: "",
      checkinDate: "",
      area: "",
      mdScreen: 768,
      mdAside: false,
     };
     this.setState(initialState)
 }

  getStepContent(stepIndex) {
      switch (stepIndex) {
        case 0:
          return <GettingStartedTab handleNext={this.handleNext.bind(this)} />
        case 1:
          return <YouTab {...this.state} handleNext={this.handleNext.bind(this)} handlePrev={this.handlePrev} getErrorText={this.getErrorText.bind(this)} getErrorStyle={this.getErrorStyle.bind(this)} errorText={this.state.errorText} errorStyle={this.state.errorStyle}/>
    
        case 2:
         return <ProjectTab {...this.state} handleNext={this.handleNext.bind(this)} handlePrev={this.handlePrev} getToggleErrorText={this.getToggleErrorText.bind(this)} getErrorText={this.getErrorText.bind(this)} getErrorStyle={this.getErrorStyle.bind(this)} />
        case 3:
          return <AreaTab handleNext={this.handleNext.bind(this)} handlePrev={this.handlePrev}  {...this.state} getErrorText={this.getErrorText.bind(this)} getErrorStyle={this.getErrorStyle.bind(this)}  getToggleErrorText={this.getToggleErrorText.bind(this)} />
        default:
          //to do: handle the case where all tabs are finished?
          return <SubmissionTab resetStepper={this.resetStepper.bind(this)} setInitialState={this.setInitialState.bind(this)} getErrorStyle={this.getErrorStyle.bind(this)} handleSubmission={this.handleSubmission.bind(this)} handlePrev={this.handlePrev}  {...this.state}/>;
    }
  }

  resetStepper(){
    this.setState({stepIndex: 0, finished: false})
  }

  getHelpText(){
    // if(this.state.stepIndex > 0){
      return(
        <div className="TabContainer__group TabContainer__help">
          <div className="TabContainer__help-description">* Required field</div> 
           <div className="TabContainer__help-description">Having trouble? Call us at (505)454-3586 or <a href="mailto:hello@vegetationtreatments.org"> email us</a>.</div> 
        </div>
      )
    // }
  }

  uploadOrExtent(){
    if(!this.state.mdAside){
      if(this.state.count === "many"){
        return "Upload"
      }
      else{
        return "Area"
      }
    }
  }


  getHorizontalContent(stepIndex){
    // if(this.props.formComplete){
    //   return <SubmissionTab />
    // }
    // else{
      return this.getStepContent(stepIndex)
    // }

    
  }

  renderHello(){
    if(!this.state.mdAside){
      return "Hello"
    }
  }

  renderYou(){
    if(!this.state.mdAside){
      return "You"
    }
  }

  renderTreatment(){
    if(!this.state.mdAside){
      return "Treatment"
    }
  }

  renderFinish(){
    if(!this.state.mdAside){
      return "Submit"
    }
  }

  render(){
   // const {finished, stepIndex} = this.state;
   const {stepIndex} = this.state;
    // const contentStyle = {margin: '0 16px'};
    const muiTheme = getMuiTheme({
        stepper: {
            iconColor: '#5abee9' // or logic to change color
        },
        datePicker: {
          selectColor: '#5abee9',
          headerColor: '#5abee9',
          color: '#5abee9',
        }
    })

    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div className="TabContainer">
          <Stepper activeStep={stepIndex} style={{backgroundColor:'#5b687e', minHeight: '72px'}} >
            <Step style={{color: 'white'}}>
              <StepLabel style={{color: 'white', fontSize: '12px'}}>{this.renderHello()}</StepLabel>
            </Step>
            <Step>
              <StepLabel style={{color: 'white', fontSize: '12px'}}>{this.renderYou()}</StepLabel>
            </Step>
            <Step>
              <StepLabel style={{color: 'white', fontSize: '12px'}}>{this.renderTreatment()}</StepLabel>
            </Step>
            <Step>
              <StepLabel style={{color: 'white', fontSize: '12px'}} >{this.uploadOrExtent()}</StepLabel>
            </Step>
            <Step>
              <StepLabel style={{color: 'white', fontSize: '12px'}} >{this.renderFinish()}</StepLabel>
            </Step>
          </Stepper>
        <div className="TabContainer__scroll">
          { this.getHorizontalContent(stepIndex)}
          {this.getHelpText()}
          </div>
        </div>
      </MuiThemeProvider>
    );
  }

}

TabContainer = connect((state) => ({
    sketching: state.sketch.sketching,
    graphic: state.sketch.graphic,
}))(TabContainer)
export default TabContainer
