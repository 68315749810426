import {handleActions} from 'redux-actions'
import Immutable from 'immutable'
import {isArray, now} from 'lodash'

let defaultState = Immutable.fromJS({
  active: [],
  lastUpdated: null,
  growls: {
    'server-error': {
      title: 'Error',
      message: 'Sorry, something happened on the server',
      level: 'error',
      autoDismiss: 0,
      position: 'tc',
      uid: 'server-error'
    },
    'group-warning': {
      title: 'Whoa there buddy',
      message: 'You just toggled all the layers!!',
      level: 'warning',
      position: 'tr',
      uid: 'group-warning'
    }
  }
})

export default handleActions({
  UPDATE_ACTIVE_GROWLS: (state, action) => {
    const newGrowls = action.payload

    // if its an array, convert it from an array to Immutable List
    // if its a single entity, create new Immutable List
    return (isArray(newGrowls))
      ? state.withMutations(s => s.set('active', Immutable.fromJS(newGrowls)).set('lastUpdated', now()))
      : state.withMutations(s => s.set('active', Immutable.List.of(newGrowls)).set('lastUpdated', now()))
  }
}, defaultState)
