import React, {Component} from "react"
// import {PropTypes} from 'prop-types'
import {connect} from "react-redux"
// import {closeModal} from '../../../../actions/uiActions'
// import NavLink from '../../NavLink/NavLink'
// import LinearProgress from 'material-ui/LinearProgress';
// import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import CircularProgress from 'material-ui/CircularProgress';
import SvgIcon from 'material-ui/SvgIcon';
import FinishTab from '../FinishTab/FinishTab'
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import {resetForm, changeFormError, changeSubmitComplete} from '../../../../actions/formActions'
import {resetSketch} from '../../../../actions/esri/sketchActions'

require('./SubmissionTab.scss');

class SubmissionTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      correctAnswer: false,
      submitting: false,
      formError: false
    }

   }

  getDescriptionValue(){
    return (this.props.uiDescription) ? this.props.uiDescription : "Submitting form"
  }

  getProgressCircle(){
    if(this.props.uiDescription !== "We've sent you a confirmation email."){
      return <CircularProgress color="#efce06" mode="indeterminate" style={{padding: '40px'}}/>
    }
    else{
      let iconStyles = {
        height: 40,
        width: 40,
        padding: '50px'
      }
      return(
        <SvgIcon color={"#5abee9"} style={iconStyles} >
          <path d="M0 0h24v24H0z" fill="none"/>
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
        </SvgIcon>
      )
    }
  }

  getHeader(){
    if(this.props.uiDescription !== "We've sent you a confirmation email."){
      return "Processing Your Submission"
    }
    else{
      return "Thanks For Your Submission!"
    }
  }


  handleSubmission(){
    this.setState({correctAnswer: true, submitting: true})
    this.props.handleSubmission()
  }

  handlePrev(){
    this.setState({correctAnswer: false, submitting: false})
    this.props.handlePrev(this.state)
    this.props.dispatch(changeFormError(false))
  }

  backIsDisabled(){
    return (this.state.submitting && !this.state.formError && this.props.uiDescription !== "We've sent you a confirmation email.") ? true : false
  }

  nextIsDisabled(){
    return (this.state.submitting && !this.props.submitComplete && !this.state.formError) ? true : false
  }

  static getDerivedStateFromProps(props, state){
    return ({formError: props.formError})
  }


handleResetRequest(){
    this.props.setInitialState()
    this.props.dispatch(resetSketch())
    this.props.resetStepper() 
    this.props.dispatch(resetForm(true))
    this.props.dispatch(changeFormError(false))
    this.props.dispatch(changeSubmitComplete(false))
    this.setState({submitting: false})    
}
  
  render() {
    if(this.state.correctAnswer){
          return (
      <div>
        <div className="TabContainer__group">
          <h1>Submission</h1>
          <div className="TabContainer__header-description"><p>{this.getHeader()}</p></div>
          <div className="TabContainer__group SubmissionTab__group">
            <div className="SubmissionTab__upload-category-label">{this.getDescriptionValue()}</div>
            {this.getProgressCircle()}
          </div>
        </div>
          <div className="TabContainer__group-button">
            <FlatButton labelStyle={{color: "white"}}  disabled={this.backIsDisabled()}  label="Back" onClick={this.handlePrev.bind(this)} style={{marginRight: 12}}/>
            <RaisedButton disabled={this.nextIsDisabled()} label="Reset" labelStyle={{color: 'white'}} backgroundColor={"#efce16"}  onClick={this.handleResetRequest.bind(this)}/>
          </div>
      </div>
    )
    }
    else {
      return (
        <FinishTab {...this.props} getErrorStyle={this.props.getErrorStyle.bind(this)} handleSubmission={this.handleSubmission.bind(this)} handlePrev={this.props.handlePrev}/>
      )
    }
  }
}

SubmissionTab = connect(state => {
  let modalProps = state.ui.get('submitProps')
  let formError = state.form.formError
  let submitComplete = state.form.submitComplete
  return {...modalProps, formError, submitComplete}
})(SubmissionTab)

export default SubmissionTab
