// TODO: keyboard listener is always active because Drawers are always mounted

import React, {Component} from 'react'
import {PropTypes} from 'prop-types'
import {connect} from 'react-redux'
import classNames from 'classnames'
import Icon from '../Icon/Icon'
import KeyboardListener from '../../utils/KeyboardListener'
// import NavLink from '../NavLink/NavLink'
import {openDrawer, closeDrawer} from '../../../actions/uiActions'
import {updateSelection} from '../../../actions/esri/esriMapActions'

require('./Drawer.scss')

const KEYS = {
  left:  37,
  up:    38,
  right: 39,
  down:  40,
  esc:   27
}

class Drawer extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    trigger: PropTypes.node,
    noTrigger: PropTypes.bool,
    propsObj: PropTypes.object,
    backdrop: PropTypes.bool
  }

  static defaultProps = {
    propsObjs: {},
    backdrop: false,
    noTrigger: false
  }

  getClassNames() {
    return classNames('Drawer', this.props.className, {
      "Drawer--open": this.props.activeDrawer === this.props.id
    })
  }

  openDrawer() {
    this.props.dispatch(openDrawer(this.props.id), this.props.propsObj)
  }

  closeDrawer() {
    this.props.dispatch(closeDrawer(this.props.id))
    this.props.dispatch(updateSelection(0, 0, null, {}))
  }

  renderTrigger() {
    return this.props.trigger || <Icon className="Drawer__trigger-icon" name="menu"/>
  }


  render() {

    return(
      <div className={this.getClassNames()}>
        {!this.props.noTrigger &&
          <div className="Drawer__trigger" onClick={this.openDrawer.bind(this)}>
            {this.renderTrigger()}
          </div>
        }
        {this.props.backdrop && <div className="Drawer__backdrop" onClick={this.closeDrawer.bind(this)}></div>}
        <div className="Drawer__drawer">
          <Icon className="Drawer__close" name="exit" onClick={this.closeDrawer.bind(this)}/>
          {this.props.children}
        </div>
        <KeyboardListener keyEvent={{which: KEYS.esc}} handler={this.closeDrawer.bind(this)} />
      </div>  
    )
  }
}

Drawer = connect(state => ({
  activeDrawer: state.ui.get('activeDrawer'),
}))(Drawer)
export default Drawer
