import React, {Component} from 'react'
import classNames from 'classnames'
import Icon from '../Icon/Icon'
import {PropTypes} from 'prop-types'

require('./Group.scss')

class Group extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    visible: PropTypes.bool,
    name: PropTypes.string.isRequired,
    open: PropTypes.bool,
    onToggle: PropTypes.func.isRequired
  }

  static defaultProps = {
    name: 'Needs Group Name',
    open: false,
    onToggle: () => {},
    onSelect: () => {}
  }

  constructor(props) {
    super(props)

    this.state = {
      collapsed: !props.open
    }
  }

  getClassNames() { return classNames('Group', this.props.className) }

  getItemsClassNames() {
    return classNames('Group__items', {
      'Groups__items--collapsed': this.state.collapsed
    })
  }

  // this goes all the way up to ListContainer
  handleToggle(e) {
    // do something internal to this component here
    this.props.onToggle(this.props.id, e)
  }
  
  handleCollapse(e) {
    e.stopPropagation()
    this.setState({collapsed: !this.state.collapsed})
  }

  getCollapseClassName() {
    return classNames('Group__collapse-icon', {
      'Group__collapse-icon--open': !this.state.collapsed
    })
  }


  render() {
    const {name, children} = this.props

    return(
      <div className={this.getClassNames()}>
        <header className="Group__header">
          <div className="Group__collapse" onClick={this.handleCollapse.bind(this)}>
            <Icon name="chevron-right" className={this.getCollapseClassName()}/>
          </div>
          <div className="Group__name">{name}</div>
          {!this.props.filterValue}
        </header>
        <div className={this.getItemsClassNames()}>{children}</div>
      </div>
    )
  }
}

export default Group
