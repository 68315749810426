import React, {Component} from "react"
import {connect} from "react-redux"
import Modal from "../../Modal/Modal"


require('./AboutModal.scss');

const id = "aboutModal"

class AboutModal extends Component {
  static propTypes = {
    
  }  
  render() {

    return (
      <div>
        <Modal className="AboutModal" id={id} ref="modal">
          <h2 className="Modal__header">About the Upload Tool</h2>
          <div className="AboutModal__description">
            
            <p>Vegetation treatments play a critical role in the health of our forests. This upload tool was developed to give federal, state, and private entities the means to contribute their vegetation treatments to <a rel="noopener noreferrer" target="_blank" href="http://nmfwri.org/resources/gis-projects/nm-vegetation-treatment-mapping">NMFWRI Vegetation Treatment Database</a>.</p>
            <p>This project is maintained by the New Mexico Forest & Watershed Restoration Institute <a rel="noopener noreferrer" target="_blank" href="http://nmfwri.org/">(NMFWRI)</a> and is a companion tool for the NM Vegetation Treatments<a href="http://arcg.is/2oCJgjI" rel="noopener noreferrer" target="_blank"> Database</a></p>
            <p>It is funded by NM State Forestry and the Department of Homeland Security.</p>

            <p>Want to know more? <a rel="noopener noreferrer" target="_blank" href="http://nmfwri.org/resources/gis-projects/nm-vegetation-treatment-mapping">Read</a> about the history of this tool.</p> 
            </div>


        </Modal>
      </div>
    )
  }
}

AboutModal = connect(state => {
  let props = state.ui.get('modalProps')
  return {...props}
})(AboutModal)

AboutModal.id = id
export default AboutModal
