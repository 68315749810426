import React, {Component} from 'react'
// import {PropTypes} from 'prop-types'
// import classNames from 'classnames'
import {connect} from 'react-redux'
import Tab from '../../Tab/Tab'
import TextField from 'material-ui/TextField'
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';

require('./YouTab.scss')
class YouTab extends Component {


    constructor(props) {
      super(props)
      this.state = {
        name: "",
        agency: "",
        email: "",
        phone: "",
        nameError: true,
        agencyError: true,
        emailError: true,
        phoneError: true,
        tabError: false
      }

   } 

  phoneChange(event){
    var phoneDisplay = event
    var phoneError = false
    if(typeof event === "object"){
      let phoneTemp = event.target.value.replace(/[^0-9]+/g,"")
      phoneDisplay = phoneTemp
      if(phoneTemp.length <= 10){

        if(phoneTemp.length >= 4 && phoneTemp.length < 7) {
          let part1 = phoneTemp.substring(0, 3)
          let part2 = phoneTemp.substring(3, 6)
          phoneDisplay = "(" + part1 + ") " +  part2
        }
        else if (phoneTemp.length >= 7 && phoneTemp.length < 11){
           let part1 = phoneTemp.substring(0, 3)
          let part2 = phoneTemp.substring(3, 6)
          let part3 = phoneTemp.substring(6,10)
          phoneDisplay = "(" + part1 + ") " +  part2 + "-" + part3
        }
        else if(phoneTemp.length >= 1 && phoneTemp.length <4){
          let part1 = phoneTemp.substring(0, 3)
          phoneDisplay = "(" + part1
        }
        else{
          phoneDisplay = ""
        }
      }
      else{
        phoneDisplay = event.target.value.substring(0,14)
      }
    }

    (phoneDisplay.length === 14) ? phoneError = false : phoneError = true
    this.handlePhoneChange(phoneDisplay, phoneError)
  } 

    handleNameChange(event) {
      if(typeof event === "object"){
        (event.target.value.length > 0) ? this.setState({name: event.target.value, nameError: false}) : this.setState({name: event.target.value, nameError: true});
    
      }
      else if(typeof event === "string"){
        (event.length > 0) ? this.setState({name: event, nameError: false}) : this.setState({name: event, nameError: true});
    
      }
    }

    handleAgencyChange(event) {
     if(typeof event === "object"){  
      (event.target.value.length > 0) ? this.setState({agency: event.target.value, agencyError: false}) : this.setState({agency: event.target.value, agencyError: true});
      }
      else if(typeof event === "string"){
        (event.length > 0) ? this.setState({agency: event, agencyError: false}) : this.setState({agency: event, agencyError: true});
      }  
    }

    handleEmailChange(event) {
     if(typeof event === "object"){ 
      (event.target.value.length > 0) ? this.setState({email: event.target.value, emailError: false}) : this.setState({email: event.target.value, emailError: true}); 
      }
      else if(typeof event === "string"){
        (event.length > 0) ? this.setState({email: event, emailError: false}) : this.setState({email: event, emailError: true}); 
      }
    }

    handlePhoneChange(phoneDisplay, phoneError) {
      this.setState({phone: phoneDisplay, phoneError});
    }

    handlePrev(){
      this.props.handlePrev(this.state)
    }

    handleNext(e){
      e.preventDefault()
      if(this.youTabError()){
        this.setState({tabError: true})
      }
      else{
        //go to next (from parents)
        this.props.handleNext(this.state)
      }
    }

  youTabError(){
    return (this.state.nameError || this.state.agencyError || this.state.emailError || this.state.phoneError)
  }

  componentDidMount(){
    this.handleNameChange(this.props.name)
    this.handleAgencyChange(this.props.agency)
    this.handleEmailChange(this.props.email)
    this.phoneChange(this.props.phone)
  }



  render(){
    return (
     <Tab {...this.props}>
     <form onSubmit={this.handleNext.bind(this)}>
      <div className="TabContainer__group">
       <h1>You</h1>
       <div className="TabContainer__header-description"><p>Tell us a little about yourself</p></div>
        <TextField className="TabContainer__field" type="text" hintText="Your name" hintStyle={{fontSize:'12px', color: '#9e9d9e'}} underlineFocusStyle={{borderColor: '#5abee9'}}  floatingLabelText="Name*" onChange={this.handleNameChange.bind(this)} value={this.state.name} floatingLabelStyle={{color: 'white'}} inputStyle={{color: 'white'}} errorText={this.props.getErrorText(this.state.nameError, this.state.tabError)} errorStyle={this.props.getErrorStyle(this.state.nameError, this.state.tabError)} />
        <TextField className="TabContainer__field" type="text" hintText="Your organization" underlineFocusStyle={{borderColor: '#5abee9'}} hintStyle={{color: '#9e9d9e'}} floatingLabelText="Agency*" onChange={this.handleAgencyChange.bind(this)} value={this.state.agency} floatingLabelStyle={{color: 'white'}} inputStyle={{color: 'white'}} errorText={this.props.getErrorText(this.state.agencyError, this.state.tabError)} errorStyle={this.props.getErrorStyle(this.state.agencyError, this.state.tabError)} />
        <TextField className="TabContainer__field" hintText="hello@email.com" underlineFocusStyle={{borderColor: '#5abee9'}} hintStyle={{color: '#9e9d9e'}} type="email" floatingLabelText="Email*" onChange={this.handleEmailChange.bind(this)} value={this.state.email} floatingLabelStyle={{color: 'white'}} inputStyle={{color: 'white'}} errorText={this.props.getErrorText(this.state.emailError, this.state.tabError)} errorStyle={this.props.getErrorStyle(this.state.emailError, this.state.tabError)} />
        <TextField className="TabContainer__field" hintText="(555) 555-5555" underlineFocusStyle={{borderColor: '#5abee9'}} hintStyle={{color: '#9e9d9e'}} type="phone" floatingLabelText="Phone*" onChange={this.phoneChange.bind(this)} value={this.state.phone} floatingLabelStyle={{color: 'white'}} inputStyle={{color: 'white'}} errorText={this.props.getErrorText(this.state.phoneError, this.state.tabError)} errorStyle={this.props.getErrorStyle(this.state.phoneError, this.state.tabError)} />
      </div>
      <div className="TabContainer__group-button">
        <FlatButton labelStyle={{color: "white"}} label="Back" disabled={false} onClick={this.handlePrev.bind(this)} style={{marginRight: 12}}/>
        <RaisedButton labelStyle={{color: 'white'}} backgroundColor={"#efce16"} label="Next" type="submit" />
     </div>
      </form>
      </Tab>
      )
    } 

}


YouTab = connect((state) => ({
}))(YouTab)
export default YouTab
