import React, {Component} from 'react'
// import {PropTypes} from 'prop-types'
import {connect} from 'react-redux'
import Tab from '../../Tab/Tab'
import RaisedButton from 'material-ui/RaisedButton';

require('./GettingStartedTab.scss')
class GettingStartedTab extends Component {


    constructor(props) {
      super(props)
      this.state = {
      }

   }  


  render(){
    return (
      <Tab {...this.props} >
        <div className="TabContainer__group">
          <h1>Hello</h1> 
          <div className="TabContainer__header-description"><p>Welcome to the NM Vegetation Treatments Upload Tool </p></div>
          <div className="GettingStarted__container">
            <p>This wizard will walk you through the steps of contributing your treatment projects to the <a rel="noopener noreferrer" target="_blank" href="http://nmfwri.org/resources/gis-projects/nm-vegetation-treatment-mapping">NMFWRI Vegetation Treatment Database</a>.</p>
            <p>During the process, you will be asked to:
            <li className="GettingStarted__li">Provide your contact information</li>
            <li className="GettingStarted__li">Describe the details of your vegetation treatment</li>
            <li className="GettingStarted__li">Draw the extent of your treatment area or upload files containing the area extent (eg: shapefiles, zipped geodatabase)</li>
            </p> 
            <p>Once submitted, your vegetation treatment information will be reviewed and placed into the vegetation treatments database, which will provide a more comprehensive picture of New Mexico&#8217;s forest health for federal, state, local, and private entities.</p>
        </div>
      </div>
        <div className="TabContainer__group-button">
        <RaisedButton  labelStyle={{color: 'white'}} backgroundColor={"#efce16"} label="Get Started" onClick={this.props.handleNext.bind(this)}/>
        </div>

      </Tab>
    )
  }

}

GettingStartedTab = connect((state) => ({
}))(GettingStartedTab)
export default GettingStartedTab
