import React, {Component} from 'react'
import {connect} from 'react-redux'
import Tab from '../../Tab/Tab'
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField'
import FlatButton from 'material-ui/FlatButton';
// import {PropTypes} from 'prop-types'

require('./FinishTab.scss')
class FinishTab extends Component {


    constructor(props) {
      super(props)
      this.state = {
      number1: 0,
      number2: 2,
      answer: "",
      captchaError: false,

      }

   }  

  getCaptchaErrorText(){
    if(this.state.captchaError) {
      if(this.state.answer === ""){
        return "This field is required"
      }
      else if(!this.correctAnswer(this.state.answer)){
        return "Incorrect Answer"
      }
    }
    else {
      return ""
    }
  }

  correctAnswer(answer){
    return (answer === this.state.number1 + this.state.number2)
  }

  handleAnswer(e){
     if (e.target.value.length > 0 && this.correctAnswer(parseInt(e.target.value))){
          this.setState({answer: parseInt(e.target.value), captchaError: false}); 
     }
     else{
      this.setState({answer: parseInt(e.target.value), captchaError: true})
     }          
  }

componentDidMount(evt){
    let num1 = Math.floor(Math.random()*20)
    let num2 = Math.floor(Math.random()*20)
    this.setState({number1: num1, number2: num2})
    document.onkeydown=function(evt){
      var keyCode = evt ? (evt.which ? evt.which : evt.keyCode) : evt.keyCode;
      if(keyCode === 13){
        evt.preventDefault()
      }
    }
}

  handlePrev(){
    this.props.handlePrev(this.state)
  }

  handleSubmission(){
    if(!this.correctAnswer(this.state.answer)){
      this.setState({captchaError: true})
    }
    else{
      this.props.handleSubmission()
    }
  }

  render(){
    return (
     <Tab {...this.props}>
        <div className="TabContainer__group">
          <h1>Almost finished.</h1>
                 <div className="TabContainer__header-description"><p>Please use the back button to review your answers</p></div>
          <div className="UploadForm__group">
            <TextField  type="number" hintText="Take a guess" floatingLabelText={"What is " + this.state.number1 + " + " + this.state.number2 + "?"} onChange={this.handleAnswer.bind(this)} value={this.state.answer} underlineFocusStyle={{borderColor: '#5abee9'}} hintStyle={{color: '#9e9d9e'}} floatingLabelStyle={{color: 'white'}} inputStyle={{color: 'white'}} errorText={this.getCaptchaErrorText(this.state.captchaError)} errorStyle={this.props.getErrorStyle(this.state.captchaError)} />
          </div>      
          <div className="TabContainer__group-button">
            <FlatButton labelStyle={{color: "white"}} label="Back" disabled={false} onClick={this.handlePrev.bind(this)} style={{marginRight: 12}}/>
            <RaisedButton label="Submit" labelStyle={{color: 'white'}} backgroundColor={"#efce16"}  onClick={this.handleSubmission.bind(this)}/>
          </div>
        </div>
      </Tab>
      )
    }

}

FinishTab = connect((state) => ({
}))(FinishTab)
export default FinishTab
