// import Extent from 'esri/geometry/Extent'
// import {loadExtent} from '../../../components/utils/esriLoader'
// import React, {useEffect} from 'react'
import {queryFeatureLayer} from '../../../actions/esri/layerClickActions/featureService'
import {updateActiveGrowls} from '../../../actions/growlActions'
import {requestOpenDrawer, generateDrawerBodyFromAttributes, updateSelection, mapUpdating} from '../../../actions/esri/esriMapActions'
// import gtmDataLayer from 'utils/gtmDataLayer'



export const init = (layerView, mapPoint, resolution) => async (dispatch, getState) => {
  dispatch(mapUpdating(true))
  let layer = layerView.layer
  let extent = getQueryExtent(resolution, mapPoint)

  if(layer.lType === "feature"){
     
     await queryFeatureLayer(layer, extent).then(results => {
        if(results.length > 0){
           // gtmDataLayer({event: 'FEATURE_CLICKED', payload: layer.name + " - " + results[0].attributes[layer.uniqueField]})
           dispatch(updateSelection(layer.id, results[0].attributes[layer.uniqueField], layer.uniqueField, [results[0].geometry]))
           dispatch(requestOpenDrawer(generateDrawerBodyFromAttributes(layer.queryFields, results[0].attributes), layer.name + " Treatment Selection"))
        }
       else{
        dispatch(noResults('No results returned from query'))
       }
      })
  }
  else{
    dispatch(noResults('Please turn on a valid valid'))
  }
}

export const noResults = (message) => (dispatch) => {
  dispatch(mapUpdating(false))
  dispatch(updateActiveGrowls({
    title: 'Oops!',
    message: message,
    autoDismiss: 3, 
    level: 'warning',
    position: 'tc'
  }))
}


const getQueryExtent = (resolution, mapPoint) => {
  var tolerance = 7 * resolution;

  return ({
    type: "extent",
    xmin: mapPoint.x - tolerance,
    ymin: mapPoint.y - tolerance,
    xmax: mapPoint.x + tolerance,
    ymax: mapPoint.y + tolerance,
    spatialReference: {
      wkid: 3857
    }


  })
  // return loadExtent(mapPoint.x - tolerance, mapPoint.y - tolerance, mapPoint.x + tolerance, mapPoint.y + tolerance)


}