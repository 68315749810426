import React, {Component} from 'react'
import {connect} from 'react-redux'
import HeaderContainer from '../HeaderContainer/HeaderContainer'
import GrowlContainer from '../GrowlContainer/GrowlContainer'
import Mapp from '../Mapp/Mapp'
import AboutModal from '../../presentational/_modals/AboutModal/AboutModal'
import SubmissionModal from '../../presentational/_modals/SubmissionModal/SubmissionModal'
import {openModal} from '../../../actions/uiActions'


require('./App.scss')

class App extends Component {
  static propTypes = {}

  static defaultProps = {}

  constructor(props) {
    super(props)

     this.state = {
      params: null
     }
   }

  static getDerivedStateFromProps(props, state){
    return({params: props.params})
  }

  componentDidUpdate(){
    this.handleAction(this.state.params.action)
  }

  componentDidMount(){
    this.handleAction(this.props.params)
  }

  handleAction(action){
      if(action !== undefined && action.includes("about")){
         this.props.dispatch(openModal("aboutModal", {}))
      }
  }

  render() {
    return <div className="App">
      <HeaderContainer />

        <Mapp/>

      
      <GrowlContainer />
      <AboutModal />
      <SubmissionModal />
    </div>
  }
}

App = connect()(App)
export default App
