// TODO: This component should be renamed to be more descriptive that its for items clicked on a map

import React, {Component} from 'react'
import {connect} from 'react-redux'
import Drawer from '../../Drawer/Drawer'
import Icon from '../../Icon/Icon'
import {PropTypes} from 'prop-types'

require('./DetailDrawer.scss')

const ID = "detailDrawer"

class DetailDrawer extends Component {
  static propTypes = {
    header: PropTypes.string,
    body: PropTypes.element
  }

  static defaultProps = {
    body: <div></div>
  }

  render() {
    let { header, body: Body } = this.props
    return(
      <Drawer id={ID} noTrigger className="DetailDrawer">
        <div className="DetailDrawer__container">
        <h3>
          <Icon className="Item__icon" name="pin"/>
          {header}
        </h3>
        {Body}
        </div>
      </Drawer>
    )
  }
}

DetailDrawer = connect(state => ({
  ...state.ui.get('drawerProps'),
}))(DetailDrawer)
export default DetailDrawer
