import {toggleItem} from './esri/esriMapActions'
import {updateActiveGrowls} from './growlActions'

export const resetForm = (bool) => (dispatch, getState) => {
  if(bool){
    let state = getState()
    let groups = state.esriMap.get('results')
    .map(groupId => state.esriMap.getIn(['entities', 'groups', `${groupId}`]))
    .toJS()

    let items = getState().esriMap.getIn(['entities', 'items']).toJS()
    groups.forEach(group => {
      group.items.forEach(itemId => {
        let item = items[itemId]
        if(item.options.visible){
          dispatch(toggleItem(item.id))
        }
        else{
        }
      })
    })
  }

  dispatch({
    type: "SET_GRAPHIC",
    payload: {}
  })

  dispatch({
    type: "SET_SKETCHING",
    payload: false
  })

  dispatch({
    type: "RESET_FORM_OBJECT",
    payload: bool
  })

}

export const changeFormError = (bool) => (dispatch, getState) => {
  dispatch({
    type: "CHANGE_FORM_ERROR",
    payload: bool
  })
    
  dispatch(checkForFormError())
}

export const changeSubmitComplete = (bool) => (dispatch, getState) => {
  dispatch({
    type: "CHANGE_SUBMIT_COMPLETE",
    payload: bool
  })  
}

export const checkForFormError = () => (dispatch, getState) => {
  if(getState().form.formError){

    dispatch(updateActiveGrowls({
      title: "Submission Error!",
     message: `Please contact us.`,
      autoDismiss: 5, 
      level: 'error',
      position: 'tc'
    }))
    
  }

}
