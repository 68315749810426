import {handleActions} from "redux-actions"
import Immutable from "immutable"
// import SimpleRenderer from 'esri/renderers/SimpleRenderer'
//import SimpleMarkerSymbol from  'esri/symbols/SimpleMarkerSymbol'
//import SimpleLineSymbol from  'esri/symbols/SimpleLineSymbol'
//import SimpleFillSymbol from  'esri/symbols/SimpleFillSymbol'

let defaultState = Immutable.fromJS({
  selection: {
    layerId: 0,
    featureId: 0,
    geometry: [],
  },
  results: [23],
  mapUpdating: false,
  entities: {
    groups: {
      23: {
        name: "Show Existing Treatment Areas",
        type: "group",
        id: 23,
        visible: false,
        listVisible: true, // determines left side visibility
        open: false,
        items: [5, 4, 6, 7]
      },

    },
    items: {
        6: {
        id: 6,
        
        group: 23,
        listVisible: true,
        options: {
          lType: "feature",
          name: 'Historic',
          id: 6,
          visible: false,
          disabled: false,
          url: 'https://services1.arcgis.com/Pat8LQYI0Udhgy4G/ArcGIS/rest/services/NMVeg_July2017/FeatureServer/6',
          outFields: ['*'],
          uniqueField: "OBJECTID",
          queryFields: [
            {fieldName: "Year_Cal", nickName: "Year", textType: "string"},
            {fieldName: "Name_Proj", nickName: "Project", textType: "title"},
            {fieldName: "Agency", nickName: "Agency", textType: "agency"},
            {fieldName: "Description", nickName: "Description", textType: "string"},
          ],
          geometryType: "polygon",
          rendererProps: {
            type: "unique-value",
            field: "OBJECTID",
            defaultSymbol: {
              type: 'simple-fill',
              color: "#DFC27D",
              outline: {
                width: 0,
                color: "#ff9999"
              }
            },
            unique: [],
            visualVariables: [{
              type: "opacity",
              field: "OBJECTID",
              stops: [
              {
                value: 0, opacity: 0.5
              }]
            }],
          },       
        }
      },


      4: {
        id: 4,
       
        group: 23,
        listVisible: true,
        options: {
          lType: "feature",
           name: 'Completed',
          id: 4,
          visible: false,
          disabled: false,
          url: 'https://services1.arcgis.com/Pat8LQYI0Udhgy4G/arcgis/rest/services/NMVeg_July2017/FeatureServer/7',
          outFields: ['*'],
          uniqueField: "OBJECTID",
          queryFields: [
            {fieldName: "Year_Cal", nickName: "Year", textType: "string"},
            {fieldName: "Name_Proj", nickName: "Project", textType: "title"},
            {fieldName: "Agency", nickName: "Agency", textType: "agency"},
            {fieldName: "Description", nickName: "Description", textType: "string"},
          ],
          geometryType: "polygon",
          rendererProps: {
            type: "unique-value",
            field: "OBJECTID",
            defaultSymbol: {
              type: 'simple-fill',
              color: "#01665E",
              outline: {
                width: 0,
                color: "#ff9999"
              }
            },
            unique: [],
            visualVariables: [{
              type: "opacity",
              field: "OBJECTID",
              stops: [
              {
                value: 0, opacity: 0.5
              }]
            }],
          },              
        }
      },

      7 : {
        id: 7,
        group: 23,
        listVisible: true,
        options: {
          lType: "feature",
          name: 'Ongoing',
          id: 7,
          visible: false,
          disabled: false,
          url: 'https://services1.arcgis.com/Pat8LQYI0Udhgy4G/ArcGIS/rest/services/NMVeg_July2017/FeatureServer/5',
          outFields: ['*'], 
          uniqueField: "OBJECTID", 
          queryFields: [
          {fieldName: "Year_Cal", nickName: "Year", textType: "string"},
          {fieldName: "Name_Proj", nickName: "Project", textType: "title"},
          {fieldName: "Agency", nickName: "Agency", textType: "agency"},
          {fieldName: "Description", nickName: "Description", textType: "string"},
          ],
          geometryType: "polygon",
          rendererProps: {
            type: "unique-value",
            field: "OBJECTID",
            defaultSymbol: {
              type: 'simple-fill',
              color: "#80CDC1",
              outline: {
                width: 0,
                color: "#ff9999"
              }
            },
            unique: [],
            visualVariables: [{
              type: "opacity",
              field: "OBJECTID",
              stops: [
              {
                value: 0, opacity: 0.5
              }]
            }],
          },              
        }
      },

      5: {
        id: 5,
        group: 23,
        listVisible: true,
        options: {
          lType: "feature",
          name: 'Planned',
          id: 5,
          visible: false,
          disabled: false,
          url: 'https://services1.arcgis.com/Pat8LQYI0Udhgy4G/ArcGIS/rest/services/NMVeg_July2017/FeatureServer/4',
          outFields: ['*'], 
          uniqueField: "OBJECTID", 
          queryFields: [
          {fieldName: "Year_Cal", nickName: "Year", textType: "string"},
          {fieldName: "Name_Proj", nickName: "Project", textType: "title"},
          {fieldName: "Agency", nickName: "Agency", textType: "agency"},
          {fieldName: "Description", nickName: "Description", textType: "string"},
          ],
          geometryType: "polygon",
          rendererProps: {
            type: "unique-value",
            field: "OBJECTID",
            defaultSymbol: {
              type: 'simple-fill',
              color: "#80CDC1",
              outline: {
                width: 0,
                color: "#ff9999"
              }
            },
            unique: [],
            visualVariables: [{
              type: "opacity",
              field: "OBJECTID",
              stops: [
              {
                value: 0, opacity: 0.5
              }]
            }],
          },              
        }
      },


    
    }
  }
})




export default handleActions({
  MAP_ITEM_TOGGLE: (state, action) => {

    let _currentItemVisibility
    let itemId = `${action.payload}`
    let groupId = `${state.getIn(['entities', 'items', itemId, 'group'])}`

    return state.withMutations(s => s
      .updateIn(['entities', 'items', itemId, 'options', 'visible'], value => {
        _currentItemVisibility = value
        return !value
      })
      .updateIn(['entities', 'groups', groupId, 'visible'], value => {
        return (!value && !_currentItemVisibility) ? true : value
      })
    )
  },

  ITEM_TOGGLE_DISABLED: (state, action) => {
    let _disabled = action.payload.disabled
    let itemId = `${action.payload.id}`
    // let groupId = `${state.getIn(['entities', 'items', itemId, 'group'])}`
    return state.withMutations(s => s
      .updateIn(['entities', 'items', itemId, 'options', 'disabled'], value => {
        return _disabled
      })
    )
  },

  MAP_UPDATING: (state, action) => {
       return state.set('mapUpdating', action.payload)
  },

  SELECTION_UPDATE: (state, action) => {
    let selection = {}
    selection.layerId = action.payload.selection.layerId
    selection.featureId = action.payload.selection.featureId
    selection.geometry = action.payload.selection.geometry
    return state.set('selection', selection)
  },

  MAP_GROUP_TOGGLE: (state, action) => {
    let groupId = `${action.payload}`
    let group = state.getIn(['entities', 'groups', groupId])
    let groupVisibility = group.get('visible')

    let newState = group.get('items').reduce((a,b) => {
      return a.updateIn(['entities', 'items', `${b}`, 'options', 'visible'], value => !groupVisibility)
    }, state)

    return newState.setIn(['entities', 'groups', groupId, 'visible'], !groupVisibility)
  },

  SEARCH_FILTER_ITEMS_UPDATE: (state, action) => {
    return state.setIn(['entities', 'items'], action.payload)
  },

  SEARCH_FILTER_GROUPS_UPDATE: (state, action) => {
    return state.setIn(['entities', 'groups'], action.payload)
  }
}, defaultState)
