

const createMarkersForUniqueValues = (uniqueValues) => {
  // debugger
  return uniqueValues.map(u => {
    let newSymbol = {}
    newSymbol.value = u.value
    newSymbol.symbol = u.symbol //autotypcast to simple marker symbol, no need to load & create SimpleMarkerSymbol
    return newSymbol
  })
}

const createLinesForUniqueValues = (uniqueValues) => {
  // debugger
  return uniqueValues.map(u => {
    let newSymbol = {}
    newSymbol.value = u.value
    newSymbol.symbol = u.symbol //autotypcast to simple line symbol, no need to load & create SimpleLineSymbol
    return newSymbol
  })
}

const createPolygonsForUniqueValues = (uniqueValues) => {
  // debugger
  return uniqueValues.map(u => {
    let newSymbol = {}
    newSymbol.value = u.value
    newSymbol.symbol = u.symbol //autotypcast to simple fill symbol, no need to load & create SimpleFillSymbol
    return newSymbol
  })
}



export const createUniqueValueRendererByCategory = (rendererProps, geometryType) => {
  let dSymbol = null
  let uValues = []
  if(geometryType === "point"){
      dSymbol = rendererProps.defaultSymbol
      uValues = createMarkersForUniqueValues(rendererProps.unique)  //autotypcast to simple marker symbol, no need to load & create SimpleMarkerSymbol
 
  }
  else if(geometryType === "polyline"){
    dSymbol = rendererProps.defaultSymbol //autotypcast to simple line symbol, no need to load & create SimpleLineSymbol
    uValues = createLinesForUniqueValues(rendererProps.unique)
  }

    else if(geometryType === "polygon"){
    dSymbol = rendererProps.defaultSymbol //autotypcast to simple fill symbol, no need to load & create SimpleFillSymbol
    uValues = createPolygonsForUniqueValues(rendererProps.unique)
  }

  return({
    type: "unique-value",
    field: rendererProps.field,
    defaultSymbol: dSymbol,
    unique: uValues,
    visualVariables: rendererProps.visualVariables
  })
  // return loadUniqueValueRenderer(rendererProps.field, dSymbol, uValues, rendererProps.visualVariables)

}




