import {createStore, applyMiddleware, combineReducers} from "redux"
// import {mapValues} from "lodash"
import thunkMiddleware from "redux-thunk"
import promiseMiddleware from "redux-promise"
// import logger from "redux-logger"
// import gtmAnalytics from "./middleware/gtmAnalytics"

import {default as growls} from "./reducers/growlReducer"
import {default as ui} from "./reducers/uiReducer"
import {default as esriMap} from "./reducers/esriMapReducer"
import {default as form} from "./reducers/formReducer"
import {default as sketch} from "./reducers/sketchReducer"
import {default as tab} from "./reducers/tabReducer"

// const loggerMiddleware = logger({
//   stateTransformer: (state) => _.mapValues(state, val => typeof val.toJS === 'function' ? val.toJS() : val),
//   predicate: (getState, action) => __DEV__
// })

const createStoreWithMiddleware = applyMiddleware(
  promiseMiddleware,
  thunkMiddleware,
)(createStore)

// const createStoreWithMiddleware = applyMiddleware()(createStore)

const rootReducer = combineReducers({
  ui,
  growls,
  esriMap,
  form,
  sketch,
  tab
})

export default function(initialState) {
  return createStoreWithMiddleware(rootReducer, initialState)
}
