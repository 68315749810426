import React, {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'
import DetailDrawer from '../../presentational/_drawers/DetailDrawer/DetailDrawer'
import EsriMapp from '../../mapps/EsriMapp/EsriMapp'
import Icon from '../../presentational/Icon/Icon'
import OverlaySpinner from '../../presentational/OverlaySpinner/OverlaySpinner'
import TabContainer from '../TabContainer/TabContainer'

require('./Mapp.scss')

class Mapp extends Component {
  static propTypes = {}

  static defaultProps = {}

  constructor(props) {
    super(props)

     this.state = {
      asideOpen: true,
      desktopBreakpoint: 860,
      sketching: false,
      graphic: {}, 
      xsScreen: 576,
      smScreen: 769,
      mdScreen: 992,
      lgScreen: 1200,
      xsAside: false,
      smAside: false,
      mdAside: false,
      lgAside: false,
     }
   }
 
  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this))
    this.handleResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this))
  }

  handleResize(e) {
    if(window.innerWidth < this.state.smScreen){
      this.setState({
        xsAside: true,
        smAside: false,
        mdAside: false,
        lgAside: false,
      })
    }

    else if(window.innerWidth >= this.state.smScreen && window.innerWidth < this.state.mdScreen){
      this.setState({
        xsAside: false,
        smAside: true,
        mdAside: false,
        lgAside: false
      })
    }
    else if(window.innerWidth >= this.state.mdScreen && window.innerWidth < this.state.lgScreen){
      this.setState({
        xsAside: false,
        smAside: false,
        mdAside: true,
        lgAside: false
      })
    }
    else {
       this.setState({
        xsAside: false,
        smAside: false,
        mdAside: false,
        lgAside: true
      })   
    }
  }


  static getDerivedStateFromProps(props, state){

    if(state.xsAside && props.sketching && this.isEmpty(props.graphic)){
      return({
        sketching: props.sketching,
        graphic: props.graphic,
        asideOpen: false
      })
    }
    else if(state.xsAside && props.sketching && !this.isEmpty(props.graphic)){
    return({
      sketching: props.sketching,
      graphic: props.graphic,
      asideOpen: true
    })
    }
    else{
      return null
    }



  }


  getAsideClassName() {
    return classNames('Mapp__aside', {
      'Mapp__aside--fulfilled': this.props.data,
      'Mapp__aside--open': this.state.asideOpen,
      'Mapp__aside--xs': (!this.state.sketching || !this.isEmpty(this.state.graphic))  && this.state.xsAside,
      'Mapp__aside--sm': (!this.state.sketching || !this.isEmpty(this.state.graphic))  && this.state.smAside,
      'Mapp__aside--md': (!this.state.sketching || !this.isEmpty(this.state.graphic))  && this.state.mdAside,
      'Mapp__aside--lg': (!this.state.sketching || !this.isEmpty(this.state.graphic)) &&   this.state.lgAside,
      'Mapp__aside--sketching-lg': this.state.sketching && this.isEmpty(this.state.graphic) && this.state.lgAside,
      'Mapp__aside--sketching-md': this.state.sketching && this.isEmpty(this.state.graphic) && this.state.mdAside,
      'Mapp__aside--sketching-sm': this.state.sketching && this.isEmpty(this.state.graphic) && this.state.smAside,
      'Mapp__aside--sketching-xs': this.state.sketching && this.isEmpty(this.state.graphic) && this.state.xsAside,
    })
  }

    isEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

  toggleAside() {
    this.setState({asideOpen: !this.state.asideOpen})
  }

  render() {
    return <div className="Mapp">
      <EsriMapp mapUpdating={this.props.mapUpdating}/>
      {this.props.loading && <OverlaySpinner/>}
      <div className={this.getAsideClassName()}>
        <TabContainer/>
        <div className="Mapp__aside-toggle">
          <Icon name="drawer" className="Mapp__aside-toggle__icon" onClick={this.toggleAside.bind(this)} />
        </div>
      </div>
        <DetailDrawer />
        {this.props.children}
    </div>
  }
}

Mapp = connect((state) => ({
  data: state.esriMap.get('results').toJS().length,
  loading: state.esriMap.get('mapUpdating'),
  activeDrawer: state.ui.get('activeDrawer'),
  windowWidth: state.ui.get('windowWidth'),
    sketching: state.sketch.sketching,
    graphic: state.sketch.graphic,
}))(Mapp)
export default Mapp
