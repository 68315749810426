import {handleActions} from 'redux-actions'

let defaultState = {
  sketchViewModel: {},
  sketching: false,
  graphic: {}, 
  sketchForm: {},
  sketchState: null
}

export default handleActions({

  INITIATE_SKETCH: (state,action) => {
    let _newState = Object.assign({}, state);
    _newState.sketchViewModel = action.payload
    return _newState
  },
    UPDATE_SKETCH_STATE: (state, action) => {
      let _newState = Object.assign({}, state);
      _newState.sketchState = action.payload
      return _newState
  },
  UPDATE_SKETCH_FORM_OBJECT: (state, action) => {
    let _newState = Object.assign({}, state) 
    _newState.sketchForm = action.payload
    return _newState
  },

  RESET_SKETCH: (state, acrtion) => {
   let _newState = Object.assign({}, state)
   _newState.sketchViewModel.view.graphics.removeAll();
   _newState.sketchViewModel.reset();

 
   return _newState
  },
  SET_SKETCHING: (state, action) => {
    let _newState = Object.assign({}, state)
    _newState.sketching = action.payload
    return _newState
  },
  SET_GRAPHIC: (state, action) => {
  let _newState = Object.assign({}, state)
  _newState.graphic = action.payload
  return _newState
}
 
}, defaultState)